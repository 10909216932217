import { createApp } from 'vue';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts
 */
import router from '@/router';
import store from '@/store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';
import VueGoogleMaps from '@fawmi/vue-google-maps';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import { abilitiesPlugin } from '@casl/vue';
import AbilityService from '@/core/services/AbilityService';
import vue3JsonExcel from 'vue3-json-excel';

import 'bootstrap';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(abilitiesPlugin, AbilityService.ability);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();
app.use(i18n);
app.use(vue3JsonExcel);

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBLTrtjQTGVTxHZl8NJkZEqqEcLln7ixI8',
    libraries: 'places',
    // language: 'de',
  },
});

app.mount('#app');
