import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface TaxiMeterObject {
  fromGovernorateId: string;
  toGovernorateId: string;
  id: string;
  TaxiMeterPrice;
}

export interface TaxiMeterListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  taxiMeter: Array<TaxiMeterObject>;
  errors: unknown;
}

@Module
export default class TaxiMeterModule
  extends VuexModule
  implements TaxiMeterListInfo
{
  taxiMeter = [] as Array<TaxiMeterObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get taxiMeter list
   * @returns Array<TaxiMeterObject>
   */
  get taxiMeterList(): Array<TaxiMeterObject> {
    return this.taxiMeter;
  }

  /**
   * Get total items
   * @returns number
   */
  get taxiMeterListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_TAXI_METER](data) {
    this.taxiMeter = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_TAXI_METER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_TAXI_METER_LIST]() {
    this.taxiMeter = [] as Array<TaxiMeterObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_TAXI_METER_STORE]() {
    this.context.commit(Mutations.RESET_TAXI_METER_LIST);
  }

  @Action
  [Actions.CREATE_TAXI_METER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/taxi-meter', data)
      .then(() => {
        this.context.commit(Mutations.RESET_TAXI_METER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_TAXI_METER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/taxi-meter', id)
      .then(() => {
        this.context.commit(Mutations.RESET_TAXI_METER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TAXI_METER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/taxi-meter', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_TAXI_METER_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_TAXI_METER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_TAXI_METER_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_TAXI_METER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/taxi-meter', id);
  }

  @Action
  [Actions.GET_TAXI_METERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/taxi-meter')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TAXI_METER, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
