import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/Layout/BodyModule';
import BreadcrumbsModule from '@/store/modules/Layout/BreadcrumbsModule';
import ConfigModule from '@/store/modules/Layout/ConfigModule';
import RolesModule from '@/store/modules/members/RolesModule';
import GroupsModule from '@/store/modules/members/GroupsModule';
import PermissionModule from '@/store/modules/PermissionModule';
import VehicleModule from '@/store/modules/assets/VehicleModule';
import ShipmentSizeModule from '@/store/modules/assets/ShipmentSizeModule';
import AdminsModule from '@/store/modules/members/AdminsModule';
import ShipmentsModule from '@/store/modules/Shipments/ShipmentsModule';
import HubShipmentsModule from '@/store/modules/Hub-Shipments/HubShipmentsModule';
import ClientsModule from '@/store/modules/clients/ClientsModule';
import AreasModule from '@/store/modules/assets/AreasModule';
import DriversModule from '@/store/modules/assets/DriversModule';
import GovernoratesModule from '@/store/modules/assets/GovernoratesModule';
import OfficesModule from '@/store/modules/clients/OfficesModule';
import HubsModule from '@/store/modules/assets/HubsModule';
import AboutUsModule from '@/store/modules/settings/AboutUsModule';
import ContactUsModule from '@/store/modules/settings/ContactUsModule';
import UnassignedPackageModule from '@/store/modules/Hub-Shipments/UnassignedPackagesModule';
import UsersModule from '@/store/modules/members/UsersModule';
import ProductsModule from './modules/clients/ProductsModule';
import CurrencyModule from './modules/assets/CurrencyModule';
import TaxiMeterModule from './modules/assets/TaxiMeterModule';
import TermsConditionsModule from './modules/settings/TermsConditionsModule';
import PackagesModule from './modules/PackagesModule';
import CategoriesModule from './modules/clients/CategoriesModule';
import NotificationsModule from './modules/NotificationsModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ShipmentSizeModule,
    RolesModule,
    GroupsModule,
    PermissionModule,
    VehicleModule,
    AdminsModule,
    ShipmentsModule,
    HubShipmentsModule,
    ClientsModule,
    AreasModule,
    DriversModule,
    GovernoratesModule,
    OfficesModule,
    HubsModule,
    AboutUsModule,
    ContactUsModule,
    UnassignedPackageModule,
    UsersModule,
    ProductsModule,
    CurrencyModule,
    TaxiMeterModule,
    TermsConditionsModule,
    PackagesModule,
    CategoriesModule,
    NotificationsModule,
  },
});

export default store;
