import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface NotificationsObject {
  id: number;
  htmlAr: string;
  htmlEn: string;
  htmlKu: string;
  type: string;
}

export interface NotificationsListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  notifications: Array<NotificationsObject>;
  errors: unknown;
}

@Module
export default class NotificationsModule
  extends VuexModule
  implements NotificationsListInfo
{
  notifications = [] as Array<NotificationsObject>;
  notificationsByPage: { [page: number]: NotificationsObject[] } = {};
  allNotificationsSeen = false;
  hasNewNotifications = false;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get notifications list
   * @returns Array<notificationsObject>
   */
  get notificationsList(): Array<NotificationsObject> {
    return this.notifications || [];
  }

  get notificationSeen() {
    return this.allNotificationsSeen;
  }

  /**
   * Get total items
   * @returns number
   */
  get notificationsListCount(): number {
    return this.itemCount;
  }

  get hasNewNotification() {
    return this.hasNewNotifications;
  }

  @Mutation
  [Mutations.SET_NOTIFICATONS]({ data }) {
    this.allNotificationsSeen = data.allNotificationsSeen
      ? data.allNotificationsSeen
      : false;
    this.notificationsByPage[this.page] = data.data;
    this.notifications = Object.values(this.notificationsByPage).flat();
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_NOTIFICATONS_CURRENT_PAGE](val) {
    if (this.page !== this.pageCount) {
      this.page += val;
    }
  }

  get getPage() {
    return this.page;
  }

  get getPagesCount() {
    return this.pageCount;
  }

  @Mutation
  [Mutations.RESET_NOTIFICATONS_LIST]() {
    this.notifications = [] as Array<NotificationsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.GET_NOTIFICATION]({ id, data }) {
    const payload = {
      ...data,
      isSeen: true,
    };

    ApiService.setAuthorizationHeader();
    return ApiService.update(`/v1/notification`, id, payload)
      .then(() => {
        this.context.commit(Mutations.RESET_NOTIFICATONS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_NOTIFICATONS_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_NOTIFICATONS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_NOTIFICATIONS]() {
    const params = { page: this.page };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/v1/notification', { params })
      .then(({ data }) => {
        const currentTime: Date = new Date();
        data.data = data.data.map((r) => {
          const createdAtTime: Date = new Date(r.createdat);
          const timeDifferenceInSeconds: number = Math.floor(
            (currentTime.getTime() - createdAtTime.getTime()) / 1000
          );
          const timeDifferenceInHours: number = Math.abs(
            Math.floor(timeDifferenceInSeconds / 3600)
          );

          const timeDifferenceInMinutes: number = Math.abs(
            Math.floor(timeDifferenceInSeconds / 60)
          );

          if (timeDifferenceInMinutes < 1) {
            r.createdat = 'Just now';
          } else if (timeDifferenceInMinutes < 60) {
            r.createdat = `${timeDifferenceInMinutes} ${
              timeDifferenceInMinutes === 1 ? 'minute' : 'minutes'
            } ago`;
          } else if (timeDifferenceInHours < 24) {
            r.createdat = `${timeDifferenceInHours} ${
              timeDifferenceInHours === 1 ? 'hour' : 'hours'
            } ago`;
          } else if (timeDifferenceInHours < 24 * 30) {
            const daysAgo = Math.floor(timeDifferenceInHours / 24);
            r.createdat = `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
          } else if (timeDifferenceInHours < 24 * 30 * 12) {
            const monthsAgo = Math.floor(timeDifferenceInHours / (24 * 30));
            r.createdat = `${monthsAgo} ${
              monthsAgo === 1 ? 'month' : 'months'
            } ago`;
          } else {
            const yearsAgo = Math.floor(timeDifferenceInHours / (24 * 30 * 12));
            r.createdat = `${yearsAgo} ${
              yearsAgo === 1 ? 'year' : 'years'
            } ago`;
          }

          return r;
        });
        this.context.commit(Mutations.SET_NOTIFICATONS, { data });
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
