import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface UnassignedPackagesObject {
  id: number;
  name: Array<any>;
  description: Array<any>;
  slug: string;
  isActive: boolean;
  title: string;
}

export interface unassignedPackagesListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  unassignedPackage: Array<UnassignedPackagesObject>;
  errors: unknown;
}

export interface PackagesFilterListInfo {
  name: string;
  phone: string;
  driverName: string;
  trackNumber: string;
  docId: string;
  originType: string;
  statusId: string;
  areaName: string;
  areaId: any;
  governorateId: string;
  startDate: string;
  endDate: string;
  shipmentTrackNumber: string;
  officeName: string;
  hubId: any;
}

@Module
export default class UnassignedPackageModule
  extends VuexModule
  implements unassignedPackagesListInfo
{
  unassignedPackage = [] as Array<UnassignedPackagesObject>;
  allUnassignedPackage = [] as Array<UnassignedPackagesObject>;
  checkedPackages = [];
  packagesFilter = {} as PackagesFilterListInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get unassignedPackage list
   * @returns Array<UnassignedPackagesObject>
   */
  get unassignedPackagesList(): Array<UnassignedPackagesObject> {
    return this.unassignedPackage;
  }

  /**
   * Get unassignedPackage list
   * @returns Array<UnassignedPackagesObject>
   */
  get allUnassignedPackageList(): Array<UnassignedPackagesObject> {
    return this.allUnassignedPackage;
  }

  /**
   * Get total items
   * @returns number
   */
  get unassignedPackagesListCount(): number {
    return this.itemCount;
  }

  get getPackagesArray(): any[] {
    return this.checkedPackages;
  }

  @Mutation
  [Mutations.SET_UNASSIGNED_PACKAGES](data) {
    this.unassignedPackage = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_UNASSIGNED_PACKAGES_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.SET_PACKAGES_ARRAY](checkedPackages) {
    this.checkedPackages = checkedPackages;
  }

  @Mutation
  [Mutations.RESET_UNASSIGNED_PACKAGES_LIST]() {
    this.unassignedPackage = [] as Array<UnassignedPackagesObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_UNASSIGNED_PACKAGES](data) {
    this.allUnassignedPackage = data.data;
  }

  @Mutation
  [Mutations.SET_UNASSIGNED_PACKAGES_FILTER](obj) {
    this.packagesFilter.name = String(obj.name);
    this.packagesFilter.phone = obj.phone;
    this.packagesFilter.trackNumber = obj.trackNumber;
    this.packagesFilter.driverName = obj.driverName;
    this.packagesFilter.docId = obj.docId;
    this.packagesFilter.statusId = obj.statusId;
    this.packagesFilter.originType = obj.originType;
    this.packagesFilter.areaName = obj.areaName;
    this.packagesFilter.areaId = obj.areaId;
    this.packagesFilter.governorateId = obj.governorateId;
    this.packagesFilter.startDate = obj.startDate;
    this.packagesFilter.endDate = obj.endDate;
    this.packagesFilter.hubId = obj.hubId;
    this.packagesFilter.officeName = obj.officeName;
    this.packagesFilter.shipmentTrackNumber = obj.shipmentTrackNumber;
  }

  @Action
  [Actions.UPDATE_PACKAGES_ARRAY](checkedPackages) {
    this.context.commit(Mutations.SET_PACKAGES_ARRAY, checkedPackages);
  }

  @Action
  [Actions.UPDATE_UNASSIGNED_PACKAGES_FILTER](val) {
    this.context.commit(Mutations.SET_UNASSIGNED_PACKAGES_FILTER, val);
  }

  @Action
  [Actions.RESET_UNASSIGNED_PACKAGES_STORE]() {
    this.context.commit(Mutations.RESET_UNASSIGNED_PACKAGES_LIST);
  }

  @Action
  [Actions.CREATE_UNASSIGNED_PACKAGE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/hub-shipments/assign', data)
      .then(() => {
        this.context.commit(Mutations.RESET_UNASSIGNED_PACKAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_UNASSIGNED_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/unassignedPackage-types', id)
      .then(() => {
        this.context.commit(Mutations.RESET_UNASSIGNED_PACKAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_UNASSIGNED_PACKAGE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/unassignedPackage-types', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_UNASSIGNED_PACKAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_UNASSIGNED_PACKAGE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_UNASSIGNED_PACKAGES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_UNASSIGNED_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/unassignedPackage-types', id);
  }

  @Action
  [Actions.GET_UNASSIGNED_PACKAGES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/packages/unassigned', {
      params: {
        page: this.page,
        take: 100,
        driverName: this.packagesFilter.driverName,
        mobile: this.packagesFilter.phone,
        docId: this.packagesFilter.docId,
        name: this.packagesFilter.name,
        trackNumber: this.packagesFilter.trackNumber,
        statusId: this.packagesFilter.statusId,
        areaName: this.packagesFilter.areaName,
        areaId: this.packagesFilter.areaId,
        governorateId: this.packagesFilter.governorateId,
        startDate: this.packagesFilter.startDate,
        endDate: this.packagesFilter.endDate,
        shipmentTrackNumber: this.packagesFilter.shipmentTrackNumber,
        officeName: this.packagesFilter.officeName,
        hubId: this.packagesFilter.hubId,
      },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UNASSIGNED_PACKAGES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
  @Action
  [Actions.GET_ALL_UNASSIGNED_PACKAGES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/packages/unassigned/all`)
      .then(({ data }) => {
        // data.data = data.data.map((i) => {
        //   return { title: i.trackNumber, id: i.id };
        // });
        this.context.commit(Mutations.SET_ALL_UNASSIGNED_PACKAGES, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
