import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface CurrencyObject {
  id: number;
  code: string;
  exchangeRate: number;
}

export interface CurrencyListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  currencies: Array<CurrencyObject>;
  errors: unknown;
}

@Module
export default class CurrenciesModule
  extends VuexModule
  implements CurrencyListInfo
{
  currencies = [] as Array<CurrencyObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get currency list
   * @returns Array<CurrencyObject>
   */
  get currenciesList(): Array<CurrencyObject> {
    return this.currencies;
  }

  /**
   * Get total items
   * @returns number
   */
  get currenciesListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CURRENCIES](data) {
    this.currencies = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CURRENSY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_CURRENCIES_LIST]() {
    this.currencies = [] as Array<CurrencyObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_CURRENCIES_STORE]() {
    this.context.commit(Mutations.RESET_CURRENCIES_LIST);
  }

  @Action
  [Actions.CREATE_CURRENCY](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/currency', data)
      .then(() => {
        this.context.commit(Mutations.RESET_CURRENCIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_CURRENCY](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/currency', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CURRENCIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CURRENCY]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/currency', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CURRENCIES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CURRENCY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CURRENSY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CURRENCY_ID](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/currency', id);
  }

  @Action
  [Actions.GET_CURRENCIES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/currency', { params: { page: this.page } })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CURRENCIES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
