enum Actions {
  // action types
  CLEAR_ERRORS = 'clearErrors',
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  VERIFY_AUTH = 'verifyAuth',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  CHANGE_LOCALE = 'changeLocale',
  RESET_ROLES_STORE = 'resetRolesStore',
  CREATE_ROLE = 'createRole',
  DELETE_ROLE = 'deleteRole',
  UPDATE_ROLE = 'updateRole',
  UPDATE_ROLE_CURRENT_PAGE = 'updateRoleCurrentpage',
  GET_ROLE = 'getRole',
  GET_ROLES = 'getRoles',
  GET_ROLES_GROUPS = 'getRolesGroups',
  GET_PERMISSIONS = 'getPermission',
  RESET_GROUPS_STORE = 'resetGroupsStore',
  CREATE_GROUP = 'createGroup',
  DELETE_GROUP = 'deleteGroup',
  UPDATE_GROUP = 'updateGroup',
  UPDATE_GROUP_CURRENT_PAGE = 'updateGroupCurrentPage',
  GET_GROUP = 'getGroup',
  GET_GROUPS = 'getGroups',
  RESET_SHIPMENT_SIZE_STORE = 'RESET_SHIPMENT_SIZE_STORE',
  CREATE_SHIPMENT_SIZE = 'CREATE_SHIPMENT_SIZE',
  DELETE_SHIPMENT_SIZE = 'DELETE_SHIPMENT_SIZE',
  UPDATE_SHIPMENT_SIZE = 'UPDATE_SHIPMENT_SIZE',
  UPDATE_SHIPMENT_SIZE_CURRENT_PAGE = 'UPDATE_SHIPMENT_SIZE_CURRENT_PAGE',
  GET_SHIPMENT_SIZE = 'GET_SHIPMENT_SIZE',
  GET_SHIPMENT_SIZES = 'GET_SHIPMENT_SIZES',
  RESET_ADMINS_STORE = 'RESET_ADMINS_STORE',
  CREATE_ADMIN_ACCOUNT = 'createAdminAccount',
  DELETE_ADMIN_ACCOUNT = 'deleteAdminAccount',
  UPDATE_ADMIN_ACCOUNT = 'updateAdminAccount',
  UPDATE_ADMIN_CURRENT_PAGE = 'updateAdminCurrentPage',
  GET_ADMIN_USER = 'getAdminUser',
  GET_ADMIN_USERS = 'getAdminUsers',
  GET_ALL_ROLES = 'getAllRoles',
  RESET_SHIPMENTS_STORE = 'resetShipmentsStore',
  UPDATE_SHIPMENTS_FILTER = 'updateShipmentsFilter',
  DELETE_SHIPMENT = 'deleteShipment',
  DELETE_SHIPMENT_PROPERTY = 'deleteShipmentProperty',
  CREATE_SHIPMENT = 'createShipment',
  CREATE_SHIPMENT_PACKAGE = 'createShipmentPackage',
  UPDATE_SHIPMENT = 'updateShipment',
  UPDATE_SHIPMENT_CURRENT_PAGE = 'updateShipmentCurrentPage',
  GET_SHIPMENT = 'getShipment',
  GET_ALL_SHIPMENT_STATUSES = 'getAllShipmentStatuses',
  GET_ALL_SHIPMENT_ORGANISERS = 'getAllShipmentOrganisers',
  GET_SHIPMENT_PROPERTIES = 'getShipmentProperties',
  GET_SHIPMENTS = 'getShipments',
  GET_SHIPMENT_GW_OFFICES = 'getShipmentGwOffices',
  UPDATE_SHIPMENTS_PACKAGE = 'updateShipmentsPackage',
  GET_SHIPMENT_PACKAGE = 'getShipmentPackage',
  GET_HUB_AREAS = 'getHubAreas',
  RESET_HUB_SHIPMENTS_STORE = 'resetHubShipmentsStore',
  UPDATE_HUB_SHIPMENTS_FILTER = 'updateHubShipmentsFilter',
  DELETE_HUB_SHIPMENT = 'deleteHubShipment',
  DELETE_HUB_SHIPMENT_PROPERTY = 'deleteHubShipmentProperty',
  CREATE_HUB_SHIPMENT = 'createHubShipment',
  CREATE_HUB_SHIPMENT_PACKAGE = 'createHubShipmentPackage',
  UPDATE_HUB_SHIPMENT = 'updateHubShipment',
  UPDATE_HUB_SHIPMENT_CURRENT_PAGE = 'updateHubShipmentCurrentPage',
  GET_HUB_SHIPMENT = 'getHubShipment',
  GET_ALL_HUB_SHIPMENT_STATUSES = 'getAllHubShipmentStatuses',
  GET_ALL_HUB_SHIPMENT_ORGANISERS = 'getAllHubShipmentOrganisers',
  GET_HUB_SHIPMENT_PROPERTIES = 'getHubShipmentProperties',
  GET_HUB_SHIPMENTS = 'getHubShipments',
  GET_HUB_SHIPMENT_GW_OFFICES = 'getHubShipmentGwOffices',
  UPDATE_HUB_SHIPMENTS_PACKAGE = 'updateHubShipmentsPackage',
  GET_HUB_SHIPMENT_PACKAGE = 'getHubShipmentPackage',
  RESET_CLIENTS_STORE = 'RESET_CLIENTS_STORE',
  CREATE_CLIENT = 'CREATE_CLIENT',
  DELETE_CLIENT = 'DELETE_CLIENT',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  UPDATE_CLIENT_CURRENT_PAGE = 'UPDATE_CLIENT_CURRENT_PAGE',
  GET_CLIENT = 'GET_CLIENT',
  GET_CLIENTS = 'GET_CLIENTS',
  GET_VEHICLES = 'GET_VEHICLES',
  RESET_VEHICLES_STORE = 'RESET_VEHICLES_STORE',
  CREATE_VEHICLE = 'CREATE_VEHICLE',
  DELETE_VEHICLE = 'DELETE_VEHICLE',
  UPDATE_VEHICLE = 'UPDATE_VEHICLE',
  UPDATE_VEHICLE_CURRENT_PAGE = 'UPDATE_VEHICLE_CURRENT_PAGE',
  GET_VEHICLE = 'GET_VEHICLE',
  RESET_AREAS_STORE = 'RESET_AREAS_STORE',
  CREATE_AREA = 'createArea',
  DELETE_AREA = 'deleteArea',
  UPDATE_AREA = 'updateArea',
  UPDATE_AREA_CURRENT_PAGE = 'UpdateAreaCurrentPage',
  GET_AREA = 'getArea',
  GET_AREAS = 'getAreas',
  GET_GOVERNORATE = 'getGovernorate',
  RESET_DRIVERS_STORE = 'RESET_DRIVERS_STORE',
  CREATE_DRIVER = 'createDriver',
  DELETE_DRIVER = 'deleteDriver',
  UPDATE_DRIVER = 'updateDriver',
  UPDATE_DRIVER_CURRENT_PAGE = 'updateDriverCurrentPage',
  GET_DRIVER = 'getDriver',
  GET_DRIVERS = 'gerDrivers',
  GET_ALL_VEHICLES = 'getAllVehicles',
  GET_ALL_DRIVER_TYPES = 'getAllDriverTypes',
  GET_ALL_AREAS = 'getAllAreas',
  UPLOAD_DRIVER_IMAGE = 'uploadDriverImage',
  UPLOAD_LICENSE_IMAGE = 'UploadLicenseImage',
  RESET_GOVERNORATES_STORE = 'RESET_GOVERNORATES_STORE',
  CREATE_GOVERNORATE = 'CREATE_GOVERNORATE',
  DELETE_GOVERNORATE = 'DELETE_GOVERNORATE',
  UPDATE_GOVERNORATE = 'UPDATE_GOVERNORATE',
  UPDATE_GOVERNORATE_CURRENT_PAGE = 'UPDATE_GOVERNORATE_CURRENT_PAGE',
  GET_GOVERNORATES = 'GET_GOVERNORATES',
  GET_ONE_GOVERNORATE = 'GET_ONE_GOVERNORATE',
  GET_ALL_GOVERNORATES = 'GET_ALL_GOVERNORATES',
  RESET_OFFICES_STORE = 'RESET_OFFICES_STORE',
  CREATE_OFFICE = 'CREATE_OFFICE',
  DELETE_OFFICE = 'DELETE_OFFICE',
  UPDATE_OFFICE = 'UPDATE_OFFICE',
  UPDATE_OFFICE_CURRENT_PAGE = 'UPDATE_OFFICE_CURRENT_PAGE',
  GET_OFFICE = 'GET_OFFICE',
  GET_OFFICES = 'GET_OFFICES',
  GET_ALL_CLIENTS = 'GET_ALL_CLIENTS',
  GET_ALL_HUBS = 'GET_ALL_HUBS',
  GET_ALL_OFFICES = 'GET_ALL_OFFICES',
  RESET_HUBS_STORE = 'RESET_HUBS_STORE',
  CREATE_HUB = 'CREATE_HUB',
  DELETE_HUB = 'DELETE_HUB',
  UPDATE_HUB = 'UPDATE_HUB',
  UPDATE_HUB_CURRENT_PAGE = 'UPDATE_HUB_CURRENT_PAGE',
  GET_HUB = 'GET_HUB',
  GET_HUBS = 'GET_HUBS',
  RESET_ABOUT_US_STORE = 'RESET_ABOUT_US_STORE',
  DELETE_ABOUT_US = 'DELETE_ABOUT_US',
  UPDATE_ABOUT_US = 'UPDATE_ABOUT_US',
  UPDATE_ABOUT_US_CURRENT_PAGE = 'UPDATE_ABOUT_US_CURRENT_PAGE',
  GET_ABOUT_US_BY_ID = 'GET_ABOUT_US_BY_ID',
  GET_ABOUT_US = 'GET_ABOUT_US',
  UPDATE_CONTACT_CURRENT_PAGE = 'UPDATE_CONTACT_CURRENT_PAGE',
  GET_CONTACT = 'GET_CONTACT',
  GET_CONTACTS = 'GET_CONTACTS',
  DELETE_SHIPMENT_HUB_PROPERTY = 'deleteShipmentHubProperty',
  GET_ALL_DRIVERS = 'getAllDrivers',
  GET_SHIPMENT_AREAS = 'getShipmentAreas',
  RESET_UNASSIGNED_PACKAGES_STORE = 'resetUnassignedPackagesStore',
  CREATE_PACKAGE = 'createPackage',
  CREATE_UNASSIGNED_PACKAGE = 'createUnassignedPackage',
  DELETE_UNASSIGNED_PACKAGE = 'deleteUnassignedPackage',
  UPDATE_UNASSIGNED_PACKAGE = 'updateUnassignedPackage',
  UPDATE_UNASSIGNED_PACKAGE_CURRENT_PAGE = 'updateUnassignedPackageCurrentPage',
  GET_UNASSIGNED_PACKAGE = 'getUnassignedPackage',
  GET_UNASSIGNED_PACKAGES = 'getUnassignedPackages',
  GET_ALL_UNASSIGNED_PACKAGES = 'getAllUnassignedPackages',
  RESET_USERS_STORE = 'RESET_USERS_STORE',
  CREATE_USER = 'CREATE_USER',
  DELETE_USER = 'DELETE_USER',
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_CURRENT_PAGE = 'UPDATE_USER_CURRENT_PAGE',
  GET_USER = 'GET_USER',
  GET_USERS = 'GET_USERS',
  GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS',
  CREATE_CLIENT_USER = 'CREATE_CLIENT_USER',
  CLIENT_LOGIN = 'CLIENT_LOGIN',
  RESET_PRODUCTS_STORE = 'RESET_PRODUCTS_STORE',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_CURRENT_PAGE = 'UPDATE_PRODUCT_CURRENT_PAGE',
  GET_PRODUCT = 'GET_PRODUCT',
  GET_PRODUCTS = 'GET_PRODUCTS',
  UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE',
  IMPORT_PACKAGES = 'IMPORT_PACKAGES',
  CREATE_PACKAGES = 'CREATE_PACKAGES',
  GET_MERCHANT = 'GET_MERCHANT',
  DELETE_MERCHANT = 'DELETE_MERCHANT',
  UPDATE_MERCHANT_CURRENT_PAGE = 'UPDATE_MERCHANT_CURRENT_PAGE',
  GET_DRIVER_PAYMENTS = 'GET_DRIVER_PAYMENTS',
  SEND_PAYMENT_REQUEST = 'SEND_PAYMENT_REQUEST',
  GET_CURRENCY = 'GET_CURRENCY',
  RESET_CURRENCIES_STORE = 'RESET_CURRENCIES_STORE',
  CREATE_CURRENCY = 'CREATE_CURRENCY',
  DELETE_CURRENCY = 'DELETE_CURRENCY',
  UPDATE_CURRENCY = 'UPDATE_CURRENCY',
  UPDATE_CURRENCY_CURRENT_PAGE = 'UPDATE_CURRENCY_CURRENT_PAGE',
  GET_CURRENCIES = 'GET_CURRENCIES',
  GET_CURRENCY_ID = 'GET_CURRENCY_ID',
  RESET_TAXI_METER_STORE = 'RESET_TAXI_METER_STORE',
  CREATE_TAXI_METER = 'CREATE_TAXI_METER',
  DELETE_TAXI_METER = 'DELETE_TAXI_METER',
  UPDATE_TAXI_METER = 'UPDATE_TAXI_METER',
  UPDATE_TAXI_METER_CURRENT_PAGE = 'UPDATE_TAXI_METER_CURRENT_PAGE',
  GET_TAXI_METER = 'GET_TAXI_METER',
  GET_TAXI_METERS = 'GET_TAXI_METERS',
  UPDATE_SHIPMENTS_PROPERTY_CURRENT_PAGE = 'UPDATE_SHIPMENTS_PROPERTY_CURRENT_PAGE',
  UPDATE_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE = 'UPDATE_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE',
  GET_GROUP_ID = 'GET_GROUP_ID',
  RESET_PASSWORD = 'RESET_PASSWORD',
  GET_SUPPLIER_SHIPMENTS = 'GET_SUPPLIER_SHIPMENTS',
  GET_MERCHANT_SHIPMENTS = 'GET_MERCHANT_SHIPMENTS',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CHANGE_HUB_STATUS = 'CHANGE_HUB_STATUS',
  RESET_PACKAGES_STORE = 'RESET_PACKAGES_STORE',
  DELETE_PACKAGE = 'DELETE_PACKAGE',
  UPDATE_PACKAGE_CURRENT_PAGE = 'UPDATE_PACKAGE_CURRENT_PAGE',
  GET_PACKAGE = 'GET_PACKAGE',
  GET_PACKAGES = 'GET_PACKAGES',
  UPDATE_PACKAGES_FILTER = 'UPDATE_PACKAGES_FILTER',
  GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS',
  UPDATE_TERMS_AND_CONDITIONS = 'UPDATE_TERMS_AND_CONDITIONS',
  GET_TERMS_AND_CONDITIONS_BY_ID = 'GET_TERMS_AND_CONDITIONS_BY_ID',
  RESET_CATEGORIES_STORE = 'RESET_CATEGORIES_STORE',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  UPDATE_CATEGORY_CURRENT_PAGE = 'UPDATE_CATEGORY_CURRENT_PAGE',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORY = 'GET_CATEGORY',
  GET_ALL_HUB_SHIPMENTS = 'GET_ALL_HUB_SHIPMENTS',
  UPDATE_AREA_FILTER = 'UPDATE_AREA_FILTER',
  UPDATE_DRIVERS_FILTER = 'UPDATE_DRIVERS_FILTER',
  UPDATE_GOVERNORATES_FILTER = 'UPDATE_GOVERNORATES_FILTER',
  UPDATE_VEHICLES_FILTER = 'UPDATE_VEHICLES_FILTER',
  UPDATE_HUBS_FILTER = 'UPDATE_HUBS_FILTER',
  UPDATE_CLIENTS_FILTER = 'UPDATE_CLIENTS_FILTER',
  UPDATE_OFFICES_FILTER = 'UPDATE_OFFICES_FILTER',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  GET_NOTIFICATION = 'GET_NOTIFICATION',
  UPDATE_NOTIFICATONS_CURRENT_PAGE = 'UPDATE_NOTIFICATONS_CURRENT_PAGE',
  UPDATE_PACKAGES_ARRAY = 'UPDATE_PACKAGES_ARRAY',
  UPDATE_UNASSIGNED_PACKAGES_FILTER = 'UPDATE_UNASSIGNED_PACKAGES_FILTER',
  EXPORT_SHIPMENTS = 'EXPORT_SHIPMENTS',
  EXPORT_PACKAGES = 'EXPORT_PACKAGES',
  EXPORT_HUB_SHIPMENTS = 'EXPORT_HUB_SHIPMENTS',
  DELETE_HUB_PACKAGE = 'DELETE_HUB_PACKAGE',
  GET_ALL_HUBS_CREATING = 'GET_ALL_HUBS_CREATING',
  EXPORT_PACKAGES_HUB_SHEET = 'EXPORT_PACKAGES_HUB_SHEET',
  EXPORT_PACKAGES_MERCHANT_SHEET = 'EXPORT_PACKAGES_MERCHANT_SHEET',
  EXPORT_PACKAGES_SUPPLIER_SHEET = 'EXPORT_PACKAGES_SUPPLIER_SHEET',
  GET_ALL_SHIPMENT_PROPERTIES = 'GET_ALL_SHIPMENT_PROPERTIES',
  GET_ALL_HUB_SHIPMENT_PROPERTIES = 'GET_ALL_HUB_SHIPMENT_PROPERTIES',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  PURGE_AUTH = 'logOut',
  SET_AUTH = 'setAuth',
  SET_USER = 'setUser',
  SET_ERROR = 'setError',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG = 'setLayoutConfig',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_LOCALE = 'setLocale',
  SET_ROLES = 'setRoles',
  SET_ROLES_GROUPS = 'setRolesGroups',
  RESET_ROLES_LIST = 'resetRolesList',
  SET_ROLE_CURRENT_PAGE = 'setRoleCurrentPage',
  RESET_PERMISSIONS = 'resetPermissions',
  SET_PERMISSIONS = 'setPermission',
  SET_GROUPS = 'setGroups',
  SET_GROUPS_CURRENT_PAGE = 'setGroupsCurrentPage',
  RESET_GROUPS_LIST = 'resetGroupsList',
  SET_SHIPMENT_SIZE = 'SET_SHIPMENT_SIZE',
  SET_SHIPMENT_SIZE_CURRENT_PAGE = 'SET_SHIPMENT_SIZE_CURRENT_PAGE',
  RESET_SHIPMENT_SIZE_LIST = 'RESET_SHIPMENT_SIZE_LIST',
  SET_ADMIN_USERS = 'setAdminUsers',
  SET_ADMIN_CURRENT_PAGE = 'setAdminCurrentPage',
  RESET_ADMINS_LIST = 'resetAdminsList',
  SET_SHIPMENTS = 'setShipments',
  SET_SHIPMENT_PROPERTIES = 'setShipmentProperties',
  SET_SHIPMENT_ORGANISE = 'setShipmentOrganise',
  SET_SHIPMENT_STATUSES = 'setShipmentStatuses',
  SET_SHIPMENTS_CURRENT_PAGE = 'setShipmentsCurrentPage',
  RESET_SHIPMENTS_LIST = 'resetShipmentsList',
  SET_SHIPMENTS_FILTER = 'setShipmentsFilter',
  SET_SHIPMENT_GW_OFFICES = 'setShipmentGwOffices',
  SET_HUB_AREAS = 'setHubAreas',
  SET_HUB_SHIPMENTS = 'setHubShipments',
  SET_HUB_SHIPMENT_PROPERTIES = 'setHubShipmentProperties',
  SET_HUB_SHIPMENT_ORGANISE = 'setHubShipmentOrganise',
  SET_HUB_SHIPMENT_STATUSES = 'setHubShipmentStatuses',
  SET_HUB_SHIPMENTS_CURRENT_PAGE = 'setHubShipmentsCurrentPage',
  RESET_HUB_SHIPMENTS_LIST = 'resetHubShipmentsList',
  SET_HUB_SHIPMENTS_FILTER = 'setHubShipmentsFilter',
  SET_HUB_SHIPMENT_GW_OFFICES = 'setHubShipmentGwOffices',
  SET_CLIENTS = 'SET_CLIENTS',
  SET_CLIENTS_CURRENT_PAGE = 'SET_CLIENTS_CURRENT_PAGE',
  RESET_CLIENTS_LIST = 'RESET_CLIENTS_LIST',
  SET_VEHICLES = 'SET_VEHICLES',
  SET_VEHICLES_CURRENT_PAGE = 'SET_VEHICLES_CURRENT_PAGE',
  RESET_VEHICLES_LIST = 'RESET_VEHICLES_LIST',
  SET_AREAS = 'setAreas',
  SET_AREA_CURRENT_PAGE = 'setAreaCurrentPage',
  RESET_AREAS_LIST = 'resetAreasList',
  SET_GOVERNORATE = 'setGoverNorate',
  SET_DRIVERS = 'setDrivers',
  SET_DRIVER_CURRENT_PAGE = 'setDriverCurrentPage',
  RESET_DRIVERS_LIST = 'resetDriversList',
  SET_ALL_VEHICLES = 'setAllVehicles',
  SET_ALL_DRIVER_TYPES = 'setAllDriverTypes',
  SET_ALL_AREAS = 'setAllAreas',
  RESET_GOVERNORATES_LIST = 'RESET_GOVERNORATES_LIST',
  SET_GOVERNORATE_CURRENT_PAGE = 'SET_GOVERNORATE_CURRENT_PAGE',
  SET_ALL_GOVERNORATES = 'SET_ALL_GOVERNORATES',
  SET_OFFICES = 'SET_OFFICES',
  SET_OFFICES_CURRENT_PAGE = 'SET_OFFICES_CURRENT_PAGE',
  RESET_OFFICES_LIST = 'RESET_OFFICES_LIST',
  SET_ALL_CLIENTS = 'SET_ALL_CLIENTS',
  SET_ALL_HUBS = 'SET_ALL_HUBS',
  SET_ALL_OFFICES = 'SET_ALL_OFFICES',
  SET_HUBS = 'SET_HUBS',
  SET_HUB_CURRENT_PAGE = 'SET_HUB_CURRENT_PAGE',
  RESET_HUBS_LIST = 'RESET_HUBS_LIST',
  SET_ABOUT_US = 'SET_ABOUT_US',
  SET_ABOUT_US_CURRENT_PAGE = 'SET_ABOUT_US_CURRENT_PAGE',
  RESET_ABOUT_US_LIST = 'RESET_ABOUT_US_LIST',
  SET_CONTACTS = 'SET_CONTACTS',
  SET_CONTACT_CURRENT_PAGE = 'SET_CONTACT_CURRENT_PAGE',
  SET_ALL_DRIVERS = 'setAllDrivers',
  SET_SHIPMENTS_AREAS = 'setShipmentsAreas',
  SET_UNASSIGNED_PACKAGES = 'setUnassignedPackages',
  SET_UNASSIGNED_PACKAGES_CURRENT_PAGE = 'setUnassignedPackagesCurrentPage',
  RESET_UNASSIGNED_PACKAGES_LIST = 'resetUnassignedPackagesList',
  SET_ALL_UNASSIGNED_PACKAGES = 'setAllUnassignedPackages',
  SET_USERS = 'SET_USERS',
  SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE',
  RESET_USERS_LIST = 'RESET_USERS_LIST',
  SET_ALL_PERMISSIONS = 'SET_ALL_PERMISSIONS',
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCTS_CURRENT_PAGE = 'SET_PRODUCTS_CURRENT_PAGE',
  RESET_PRODUCTS_LIST = 'RESET_PRODUCTS_LIST',
  SET_MERCHANT = 'SET_MERCHANT',
  RESET_MERCHANT_LIST = 'RESET_MERCHANT_LIST',
  SET_MERCHANT_CURRENT_PAGE = 'SET_MERCHANT_CURRENT_PAGE',
  SET_CURRENCY = 'SET_CURRENCY',
  SET_CURRENCIES = 'SET_CURRENCIES',
  SET_CURRENSY_CURRENT_PAGE = 'SET_CURRENSY_CURRENT_PAGE',
  RESET_CURRENCIES_LIST = 'RESET_CURRENCIES_LIST',
  SET_TAXI_METER = 'SET_TAXI_METER',
  SET_TAXI_METER_CURRENT_PAGE = 'SET_TAXI_METER_CURRENT_PAGE',
  RESET_TAXI_METER_LIST = 'RESET_TAXI_METER_LIST',
  SET_SHIPMENTS_PROPERTY_CURRENT_PAGE = 'SET_SHIPMENTS_PROPERTY_CURRENT_PAGE',
  SET_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE = 'SET_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE',
  SET_CREATED_GROUP_ID = 'SET_CREATED_GROUP_ID',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_PACKAGES = 'SET_PACKAGES',
  SET_PACKAGES_CURRENT_PAGE = 'SET_PACKAGES_CURRENT_PAGE',
  RESET_PACKAGES_LIST = 'RESET_PACKAGES_LIST',
  SET_PACKAGES_FILTER = 'SET_PACKAGES_FILTER',
  SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS',
  RESET_TERMS_AND_CONDITIONS = 'RESET_TERMS_AND_CONDITIONS',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_CATEGORIES_CURRENT_PAGE = 'SET_CATEGORIES_CURRENT_PAGE',
  RESET_CATEGORIES_LIST = 'RESET_CATEGORIES_LIST',
  SET_AREA_FILTER = 'SET_AREA_FILTER',
  SET_DRIVERS_FILTER = 'SET_DRIVERS_FILTER',
  SET_GOVERNORATES_FILTER = 'SET_GOVERNORATES_FILTER',
  SET_VEHICLES_FILTER = 'SET_VEHICLES_FILTER',
  SET_HUBS_FILTER = 'SET_HUBS_FILTER',
  SET_CLIENTS_FILTER = 'SET_CLIENTS_FILTER',
  SET_OFFICES_FILTER = 'SET_OFFICES_FILTER',
  SET_NOTIFICATONS_CURRENT_PAGE = 'SET_NOTIFICATONS_CURRENT_PAGE',
  RESET_NOTIFICATONS_LIST = 'RESET_NOTIFICATONS_LIST',
  SET_NOTIFICATONS = 'SET_NOTIFICATONS',
  SET_HAS_NEW_NOTIFICATIONS = 'SET_HAS_NEW_NOTIFICATIONS',
  SET_PACKAGES_ARRAY = 'SET_PACKAGES_ARRAY',
  SET_UNASSIGNED_PACKAGES_FILTER = 'SET_UNASSIGNED_PACKAGES_FILTER',
  SET_EXPORT_SHIPMENTS = 'SET_EXPORT_SHIPMENTS',
  SET_EXPORT_PACKAGES = 'SET_EXPORT_PACKAGES',
}

export { Actions, Mutations };
