import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Actions, Mutations } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/sign-in',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/manage/members/roles-Listing/:id',
        name: 'roles-listing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesListing.vue'
          ),
      },
      {
        path: '/manage/members/roles-creating/:id',
        name: 'roles-creating',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesCreating.vue'
          ),
      },
      {
        path: '/manage/members/roles-editing/:id',
        name: 'roles-editing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles/RolesEditing.vue'
          ),
      },
      {
        path: '/manage/members/groups-listing',
        name: 'groups-listing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/groups/GroupsListing.vue'
          ),
      },
      {
        path: '/manage/members/groups-creating',
        name: 'groups-creating',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/roles-groups/RolesGroupsCreating.vue'
          ),
      },
      {
        path: '/manage/members/groups-editing/:id',
        name: 'groups-editing',
        component: () =>
          import(
            '@/views/manage/members/roles-permissions/groups/GroupsEditing.vue'
          ),
      },
      {
        path: '/manage/assets/vehicles-listing',
        name: 'vehicles-listing',
        component: () =>
          import('@/views/manage/assets/vehicle-types/VehiclesListing.vue'),
      },
      {
        path: '/manage/assets/vehicle-creating',
        name: 'vehicle-creating',
        component: () =>
          import('@/views/manage/assets/vehicle-types/VehicleCreating.vue'),
      },
      {
        path: '/manage/assets/vehicle-editing/:id',
        name: 'vehicle-editing',
        component: () =>
          import('@/views/manage/assets/vehicle-types/VehicleEditing.vue'),
      },
      {
        path: '/manage/assets/shipment-size-listing',
        name: 'shipment-size-listing',
        component: () =>
          import('@/views/manage/assets/shipment-size/ShipmentSizeListing.vue'),
      },
      {
        path: '/manage/assets/shipment-size-creating',
        name: 'shipment-size-creating',
        component: () =>
          import(
            '@/views/manage/assets/shipment-size/ShipmentSizeCreating.vue'
          ),
      },
      {
        path: '/manage/assets/shipment-size-editing/:id',
        name: 'shipment-size-editing',
        component: () =>
          import('@/views/manage/assets/shipment-size/ShipmentSizeEditing.vue'),
      },
      {
        path: '/manage/members/admins-listing',
        name: 'admins-listing',
        component: () =>
          import('@/views/manage/members/admins/AdminsListing.vue'),
      },
      {
        path: '/manage/members/admins-creating',
        name: 'admins-creating',
        component: () =>
          import('@/views/manage/members/admins/AdminsCreating.vue'),
      },
      {
        path: '/manage/members/admins-editing/:id',
        name: 'admins-editing',
        component: () =>
          import('@/views/manage/members/admins/AdminsEditing.vue'),
      },
      {
        path: '/manage/clients/clients-listing',
        name: 'clients-listing',
        component: () =>
          import('@/views/manage/clients/clients/ClientsListing.vue'),
      },
      {
        path: '/manage/clients/client-creating',
        name: 'client-creating',
        component: () =>
          import('@/views/manage/clients/clients/ClientCreating.vue'),
      },
      {
        path: '/manage/clients/client-editing/:id',
        name: 'client-editing',
        component: () =>
          import('@/views/manage/clients/clients/ClientEditing.vue'),
      },
      {
        path: '/manage/assets/areas-listing',
        name: 'areas-listing',
        component: () => import('@/views/manage/assets/areas/AreasListing.vue'),
      },
      {
        path: '/manage/assets/area-creating',
        name: 'area-creating',
        component: () =>
          import('@/views/manage/assets/areas/AreasCreating.vue'),
      },
      {
        path: '/manage/assets/area-editing/:id',
        name: 'area-editing',
        component: () => import('@/views/manage/assets/areas/AreaEditing.vue'),
      },
      {
        path: '/manage/assets/drivers-listing',
        name: 'drivers-listing',
        component: () =>
          import('@/views/manage/assets/drivers/DriversListing.vue'),
      },
      {
        path: '/manage/assets/driver-creating',
        name: 'driver-creating',
        component: () =>
          import('@/views/manage/assets/drivers/DriverCreating.vue'),
      },
      {
        path: '/manage/assets/driver-editing/:id',
        name: 'driver-editing',
        component: () =>
          import('@/views/manage/assets/drivers/DriverEditing.vue'),
      },
      {
        path: '/manage/assets/shipment-history/:id',
        name: 'shipment-history',
        component: () =>
          import('@/views/manage/assets/drivers/ShipmentHistory.vue'),
      },
      {
        path: '/manage/assets/driver-payment/:id',
        name: 'driver-payment',
        component: () =>
          import('@/views/manage/assets/drivers/DriverPayments.vue'),
      },
      {
        path: '/manage/assets/governorates-listing',
        name: 'governorates-listing',
        component: () =>
          import('@/views/manage/assets/governorates/GovernoratesListing.vue'),
      },
      {
        path: '/manage/assets/governorate-creating',
        name: 'governorate-creating',
        component: () =>
          import('@/views/manage/assets/governorates/GovernorateCreating.vue'),
      },
      {
        path: '/manage/assets/governorate-editing/:id',
        name: 'governorate-editing',
        component: () =>
          import('@/views/manage/assets/governorates/GovernorateEditing.vue'),
      },
      {
        path: '/manage/clients/offices-listing',
        name: 'offices-listing',
        component: () =>
          import('@/views/manage/clients/offices/OfficesListing.vue'),
      },
      {
        path: '/manage/clients/office-creating',
        name: 'office-creating',
        component: () =>
          import('@/views/manage/clients/offices/OfficeCreating.vue'),
      },
      {
        path: '/manage/clients/office-editing/:id',
        name: 'office-editing',
        component: () =>
          import('@/views/manage/clients/offices/OfficeEditing.vue'),
      },
      {
        path: '/manage/clients/products-listing',
        name: 'products-listing',
        component: () =>
          import('@/views/manage/clients/products/ProductsListing.vue'),
      },
      {
        path: '/manage/clients/product-creating',
        name: 'product-creating',
        component: () =>
          import('@/views/manage/clients/products/ProductCreating.vue'),
      },
      {
        path: '/manage/clients/product-editing/:id',
        name: 'product-editing',
        component: () =>
          import('@/views/manage/clients/products/ProductEditing.vue'),
      },
      {
        path: '/manage/shipments/supplier-shipments-listing',
        name: 'supplier-shipments-listing',
        component: () =>
          import('@/views/manage/shipments/ShipmentsSupplier.vue'),
      },
      {
        path: '/manage/shipments/merchant-shipments-listing',
        name: 'merchant-shipments-listing',
        component: () =>
          import('@/views/manage/shipments/ShipmentsMerchant.vue'),
      },
      {
        path: '/manage/shipments/shipment-creating',
        name: 'shipment-creating',
        component: () =>
          import('@/views/manage/shipments/ShipmentsCreating.vue'),
      },
      {
        path: '/manage/shipments/shipment-editing/:id',
        name: 'shipment-editing',
        component: () =>
          import('@/views/manage/shipments/ShipmentsEditing.vue'),
      },
      {
        path: '/manage/shipments/shipment-updating/:id',
        name: 'shipment-updating',
        component: () =>
          import('@/views/manage/shipments/ShipmentsUpdating.vue'),
      },
      {
        path: '/manage/hub-shipments/hub-shipment-listing',
        name: 'hub-shipment-listing',
        component: () =>
          import('@/views/manage/hub-shipments/ShipmentsListing.vue'),
      },
      {
        path: '/manage/hub-shipments/hub-shipment-creating',
        name: 'hub-shipment-creating',
        component: () =>
          import('@/views/manage/hub-shipments/ShipmentsCreating.vue'),
      },
      {
        path: '/manage/hub-shipments/hub-shipment-editing/:id',
        name: 'hub-shipment-editing',
        component: () =>
          import('@/views/manage/hub-shipments/ShipmentsEditing.vue'),
      },
      {
        path: '/manage/assets/hubs-listing',
        name: 'hubs-listing',
        component: () => import('@/views/manage/assets/hubs/HubsListing.vue'),
      },
      {
        path: '/manage/assets/hub-creating',
        name: 'hub-creating',
        component: () => import('@/views/manage/assets/hubs/HubCreating.vue'),
      },
      {
        path: '/manage/assets/hub-editing/:id',
        name: 'hub-editing',
        component: () => import('@/views/manage/assets/hubs/HubEditing.vue'),
      },
      {
        path: '/manage/settings/about-us-listing',
        name: 'about-us-listing',
        component: () => import('@/views/settings/about-us/AboutUsListing.vue'),
      },
      {
        path: '/manage/settings/about-us-editing/:id',
        name: 'about-us-editing',
        component: () => import('@/views/settings/about-us/AboutUsEditing.vue'),
      },
      {
        path: '/manage/settings/contact-us-listing',
        name: 'contact-us-listing',
        component: () =>
          import('@/views/settings/contact-us/ContactUsListing.vue'),
      },
      {
        path: '/manage/settings/contact-us-overview/:id',
        name: 'contact-us-overview',
        component: () =>
          import('@/views/settings/contact-us/ContactUsOverview.vue'),
      },
      {
        path: '/manage/settings/terms-conditions',
        name: 'terms-conditions',
        component: () =>
          import(
            '@/views/settings/terms-and-conditions/TermsConditionsListing.vue'
          ),
      },
      {
        path: '/manage/settings/terms-conditions-editing/:id',
        name: 'terms-conditions-editing',
        component: () =>
          import(
            '@/views/settings/terms-and-conditions/TermsConditionsEditing.vue'
          ),
      },
      {
        path: '/manage/members/users-listing',
        name: 'users-listing',
        component: () =>
          import('@/views/manage/members/users/UsersListing.vue'),
      },
      {
        path: '/manage/members/user-creating',
        name: 'user-creating',
        component: () =>
          import('@/views/manage/members/users/UserCreating.vue'),
      },
      {
        path: '/manage/members/user-editing/:id',
        name: 'user-editing',
        component: () => import('@/views/manage/members/users/UserEditing.vue'),
      },
      {
        path: '/manage/members/users/merchant-listing',
        name: 'merchant-listing',
        component: () =>
          import('@/views/manage/members/users/merchant/MerchantListing.vue'),
      },
      {
        path: '/manage/packages/supplier-packages-listing',
        name: 'supplier-packages-listing',
        component: () => import('@/views/manage/packages/SupplierPackages.vue'),
      },
      {
        path: '/manage/packages/merchant-packages-listing',
        name: 'merchant-packages-listing',
        component: () => import('@/views/manage/packages/MerchantPackages.vue'),
      },
      {
        path: '/manage/packages/hub-packages-listing',
        name: 'hub-packages-listing',
        component: () => import('@/views/manage/packages/HubPackages.vue'),
      },
      {
        path: '/manage/packages/unassigned-editing/:id',
        name: 'unassigned-editing',
        component: () =>
          import(
            '@/views/manage/packages/unassigned-packages/UnassignedPackagesEditing.vue'
          ),
      },
      {
        path: '/manage/packages/unassigned-listing',
        name: 'unassigned-listing',
        component: () =>
          import(
            '@/views/manage/packages/unassigned-packages/UnassignedPackagesListing.vue'
          ),
      },
      {
        path: '/manage/packages/unassigned-creating',
        name: 'unassigned-creating',
        component: () =>
          import(
            '@/views/manage/packages/unassigned-packages/UnassignedPackagesCreating.vue'
          ),
      },
      {
        path: '/manage/packages/shipment-packages',
        name: 'shipment-packages',
        component: () =>
          import(
            '@/views/manage/packages/unassigned-packages/ShipmentPackages.vue'
          ),
      },
      {
        path: '/manage/packages/packages-overview/:id',
        name: 'packages-overview',
        component: () =>
          import('@/views/manage/hub-shipments/packages/PackagesOverview.vue'),
      },

      {
        path: '/manage/packages/merchant-packages-overview/:id',
        name: 'merchant-packages-overview',
        component: () =>
          import('@/views/manage/shipments/packages/PackagesOverview.vue'),
      },
      {
        path: '/manage/packages/supplier-packages-overview/:id',
        name: 'supplier-packages-overview',
        component: () =>
          import('@/views/manage/shipments/packages/SuppliersOverview.vue'),
      },
      {
        path: '/manage/clients/categories-listing',
        name: 'categories-listing',
        component: () =>
          import('@/views/manage/clients/category/CategoriesListing.vue'),
      },
      {
        path: '/manage/clients/category-creating',
        name: 'category-creating',
        component: () =>
          import('@/views/manage/clients/category/CategoryCreating.vue'),
      },
      {
        path: '/manage/clients/category-editing/:id',
        name: 'category-editing',
        component: () =>
          import('@/views/manage/clients/category/CategoryEditing.vue'),
      },
      {
        path: '/manage/assets/taxi-meter-creating',
        name: 'taxi-meter-creating',
        component: () =>
          import('@/views/manage/assets/taxi-meter/TaxiMeterCreating.vue'),
      },
      {
        path: '/manage/assets/taxi-meter-listing',
        name: 'taxi-meter-listing',
        component: () =>
          import('@/views/manage/assets/taxi-meter/TaxiMeterListing.vue'),
      },
      {
        path: '/manage/assets/taxi-meter-editing/:id',
        name: 'taxi-meter-editing',
        component: () =>
          import('@/views/manage/assets/taxi-meter/TaxiMeterEditing.vue'),
      },
      {
        path: '/manage/assets/exchange-rate-listing',
        name: 'exchange-rate-listing',
        component: () =>
          import('@/views/manage/assets/exchange-rate/ExchangeRateListing.vue'),
      },
      {
        path: '/manage/assets/exchange-rate-creating',
        name: 'exchange-rate-creating',
        component: () =>
          import(
            '@/views/manage/assets/exchange-rate/ExchangeRateCreating.vue'
          ),
      },
      {
        path: '/manage/assets/exchange-rate-editing/:id',
        name: 'exchange-rate-editing',
        component: () =>
          import('@/views/manage/assets/exchange-rate/ExchangeRateEditing.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/authentication/SignIn.vue'),
        alias: ['sign-in-clients', 'reset-password'],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/errors/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
export default router;
