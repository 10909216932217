import { createI18n } from 'vue-i18n/index';

const messages = {
  en: {
    signInTitle: 'Sign In to LMD Admin',
    signInAppraiser: 'Sign In Appraisers',
    signInClients: 'Sign In Clients',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    continue: 'Continue',
    selectDriver: 'Select Driver',
    selectVehicle: 'Select Vehicle',
    driverType: 'Driver Type',
    title: 'Title',
    vehicle: 'Vehicle',
    alternativeLocale: 'LMD is also offered in: {0}',
    alternativeLocaleVal: 'العربية',
    pleaseWait: 'Please wait...',
    tryAgainExcl: 'Try again!',
    backToShipmentUpdating: 'Back To Shipment Updating',
    licantEmail: 'Licant Email',
    packageOverview: 'Package Overview',
    packageDetails: 'Package Details',
    customerId: 'Customer ID',
    customerSignature: 'Customer Signature',
    enterPassword: 'Enter Password',
    areYouSureQ: 'Are you sure?',
    confirmActive: 'Are you sure you want to activate it',
    packageDelete:
      'Are you sure you want to delete this package from this shipment ? By removing this package from this shipment it will be moved to the unassigned packages.',
    confirmInActive: ' Are you sure you want to inactivate it ?',
    ROLE_NAME_IS_REQUIRED_FIELD: 'Role name  is a required field',
    ROLE_GROUP_IS_REQUIRED_FIELD: 'Role group is a required field',
    TITLE_IS_REQUIRED_FIELD: 'Title is a required field',
    AR_TITLE_IS_REQUIRED_FIELD: 'Arabic title is a required field',
    EN_TITLE_IS_REQUIRED_FIELD: 'English  title is a required field',
    KU_TITLE_IS_REQUIRED_FIELD: 'English  title is a required field',
    AR_DESCRIPTION_IS_REQUIRED_FIELD: 'Arabic description is a required field',
    EN_DESCRIPTION_IS_REQUIRED_FIELD: 'English description is a required field',
    KU_DESCRIPTION_IS_REQUIRED_FIELD: 'Kurdish description is a required field',
    GOVERNORATE_IS_REQUIRED_FIELD: 'Governorate is a required field',
    DESCRIPTION_IS_REQUIRED_FIELD: 'Description is a required field',
    VEHICLEID_IS_REQUIRED_FIELD: 'Vehicle is a required field',
    MOBILE_IS_REQUIRED_FIELD: 'Mobile is a required field',
    USER_NAME_IS_REQUIRED_FIELD: 'User name is a required field',
    PHONE_IS_REQUIRED_FIELD: 'Phone Number is a required field',
    WIDTH_IS_REQUIRED_FIELD: 'Width is a required field',
    HEIGHT_IS_REQUIRED_FIELD: 'Height is a required field',
    WEIGHT_IS_REQUIRED_FIELD: 'Weight is a required field',
    OFFICE_IS_REQUIRED_FIELD: 'Office is a required field',
    DRIVER_IS_REQUIRED_FIELD: 'Driver is a required field',
    FROM_CITY_IS_REQUIRED_FIELD: 'From City is a required field',
    RECIPIENT_PHONE_NUMBER_IS_REQUIRED_FIELD:
      'Recipient phone number is a required field',
    SHIPMENT_IS_REQUIRED_FIELD: 'shipment is a reqquired field ',
    TO_CITY_IS_REQUIRED_FIELD: 'To City is a required field',
    CODE_IS_REQUIRED_FIELD: 'Code is a required field',
    EXCHANGE_RATE_IS_REQUIRED_FIELD: 'Exchange Rate is a required field',
    PASSWORD_MIN_4: 'Password minimum length is 4 characters',
    PASSWORD_MAX_20: 'Password maximum length is 20 characters',
    PASSWORD_AT_LEAST_ONE_LOWER:
      'Password must contain at least one lowercase character',
    PASSWORD_AT_LEAST_ONE_UPPER:
      'Password must contain at least one uppercase character',
    PASSWORD_AT_LEAST_ONE_Number:
      'Password must contain at least one number character',
    FULL_NAME_IS_REQUIRED_FIELD: 'Full Name is a required field',
    TYPE_USER_IS_REQUIRED_FIELD: 'Type user is a required field',
    PHONE_MIN_11: 'minimum length is 11 Number',
    PASSWORD_IS_REQUIRED_FIELD: 'Password is a required field',
    CONFIRM_PASSWORD_IS_REQUIRED_FIELD: 'Confirm Password is a required field',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    AREA_REQUIRED_FIELD: 'Area Required Field',
    EMAIL_NOT_VALID: 'Email must be a valid email',
    EMAIL_IS_REQUIRED_FIELD: 'Email is a required field',
    ROLE_IS_REQUIRED_FIELD: 'Role is a required field',
    SUCCESSFULLY_CREATED_ADMIN: 'Successfully created a new admin!',
    SUCCESSFULLY_CREATED_ROLE: 'Successfully created a new role!',
    SUCCESSFULLY_CREATED_SHIPMENT_SIZE:
      'Successfully created a new shipment size!',
    SUCCESSFULLY_CREATED_VEHICLE: 'Successfully created a new vehicle!',
    SUCCESSFULLY_UPDATED_VEHICLE: 'Successfully updated vehicle!',
    SUCCESSFULLY_CREATED_AREA: 'Successfully created a new area!',
    SUCCESSFULLY_UPDATED_ROLE: 'Successfully updated role!',
    SUCCESSFULLY_UPDATED_SHIPMENT_SIZE: 'Successfully updated shipment size!',
    SUCCESSFULLY_CREATED_GROUP: 'Successfully created a new group!',
    SUCCESSFULLY_CREATED_CLIENT: 'Successfully created a new client!',
    SUCCESSFULLY_CREATED_OFFICE: 'Successfully created a new office!',
    SUCCESSFULLY_CREATED_EXCHANGE_RATE: 'Successfully created a exchange Rate!',
    SUCCESSFULLY_UPDATED_EXCHANGE_RATE: 'Successfully updated exchange Rate!',
    SUCCESSFULLY_CREATED_HUB: 'Successfully created a new hub!',
    SUCCESSFULLY_CREATED_PACKAGE: 'Successfully created a new packages!',
    SUCCESSFULLY_CREATED_SHIPMENT: 'Successfully updated shipment!',
    SUCCESSFULLY_UPDATED_PRODUCT: 'Successfully updated product!',
    SUCCESSFULLY_CREATED_PRODUCT: 'Successfully created product!',
    SUCCESSFULLY_UPDATED_SHIPMENT: 'Successfully updated shipment!',
    SUCCESSFULLY_CREATED_GOVERNORATE: 'Successfully created a new governorate!',
    SUCCESSFULLY_CREATED_USER: 'Successfully created a new user!',
    SUCCESSFULLY_CREATED_CATEGORY: 'Successfully created a new category!',
    SUCCESSFULLY_UPDATED_CATEGORY: 'Successfully updated category!',
    SUCCESSFULLY_UPDATED_USER: 'Successfully updated user!',
    SUCCESSFULLY_UPDATED_GOVERNORATE: 'Successfully updated governorate!',
    SUCCESSFULLY_UPDATED_OFFICE: 'Successfully updated office!',
    SUCCESSFULLY_UPDATED_HUB: 'Successfully updated Hub!',
    SUCCESSFULLY_UPDATED_TAXI_METER: 'Successfully updated taxi meter!',
    SUCCESSFULLY_CREATED_TAXI_METER: 'Successfully created a new taxi meter!',
    SUCCESSFULLY_UPDATED_CLIENT: 'Successfully updated client!',
    SUCCESSFULLY_UPDATED_GROUP: 'Successfully updated group!',
    SUCCESSFULLY_UPDATED_AREA: 'Successfully updated area!',
    SUCCESSFULLY_UPDATED_ADMIN: 'Successfully updated admin!',
    SUCCESSFULLY_UPDATED_ABOUT_US: 'Successfully updated about us!',
    SUCCESSFULLY_PULLED_PACKAGES: 'Successfully pulled packages!',
    SUCCESSFULLY_CHANGED_STATUSES: 'Successfully Changed Statuses!',
    SUCCESSFULLY_UPDATED_TERMS_AND_CONDITIONS:
      'Successfully updated terms and conditions!',
    status: 'Status',
    createNewCategory: 'Create New Category',
    updateCategory: 'Update Category',
    permissionsCount: 'Up to {count} resources',
    mobileNumber: 'Mobile Number',
    confirmPassword: 'Confirm Password',
    resetPassword: 'Reset Password',
    titleEn: 'Title - english ',
    titleKu: 'Title - kurdish',
    titleAr: 'Title - Arabic',
    internalStatus: 'Internal Status',
    externalStatus: 'External Status',
    trackNumber: 'Track Number',
    tracknumber: 'Tracking Number Segments',
    changeStatus: 'Change Statuses',
    eventDate: 'Event Date',
    shipmentType: 'Shipment Type',
    totalProperties: 'Total Properties',
    addNewAdmin: 'Add New Admin',
    addNewRole: 'Add New Role',
    addNewShipmentSize: 'Add New Shipment Size',
    addNewShipments: 'Add New Shipment',
    addNewGroup: 'Add New Group',
    addNewClient: 'Add New Client',
    addNewVehicle: 'Add New Vehicle',
    addNewArea: 'Add New Area',
    addNewDriver: 'Add New Driver',
    addNewOffice: 'Add New Office',
    addNewHub: 'Add New Hub',
    addNewPackages: 'Add New Packages',
    addNewUser: 'Add New User',
    addNewProduct: 'Add New Prodcut',
    addNewTaxiMeter: 'Add New Taxi Meter',
    addNewCategory: 'Add New Category',
    selectCity: 'Select City',
    rideFare: 'Ride Fare',
    packagesCreating: 'Packages Creating',
    createPackages: 'Create Packages',
    addPackagesToExistingShipment: 'Add Packages To Existing Shipment',
    savingShipmentPackagesNote: 'Saving Shipment Packages Note',
    recipientPhoneNumber: 'Recipient Phone Number',
    secondRecipientPhoneNumber: 'Second Recipient Phone Number',
    secondPhoneNumber: 'Second Phone Number',
    addNewGovernorate: 'Add New Governorate',
    shipmentDetails: 'Shipment Details',
    auctionDetails: 'Auction Details',
    importPackages: 'Import Packages',
    shipments: 'Shipments',
    rolesListing: 'Roles Listing',
    roleCreating: 'Roles Creating',
    roleUpdating: 'Roles Updating',
    productsListing: 'Products Listing',
    producctCreating: 'Product Creating',
    productUpdating: 'Product Updating',
    usersListing: 'Users Listing',
    userCreating: 'User Creating',
    userUpdating: 'User Updating',
    categoriesListing: 'Categories Listing',
    categoryCreating: 'Category Creating',
    categoryUpdating: 'Category Updating',
    merchantListing: 'Merchant Listing',
    shipmentsListing: 'Shipments Listing',
    shipmentCreating: 'Shipment Creating',
    shipmentUpdating: 'Shipment Updating',
    termsAndConditionsListing: 'Terms And Conditions Listing',
    termsAndConditionsUpdating: 'Terms And Conditions Updating',
    aboutUsListing: 'About Us Listing',
    aboutUsUpdating: 'About Us Updating',
    createNewRole: 'Create New Role',
    createNewClient: 'Create New Client',
    createNewgroup: 'Create New Group',
    createNewShipmentSize: 'Create New Shipment Size',
    createNewAdmin: 'Create New Admin',
    createNewHub: 'Create New Hub',
    createNewVehicle: 'Create New Vehicle',
    createNewGovernorate: 'Create New Governorate',
    createNewUser: 'Create New User',
    createNewGroup: 'Create New Group',
    shipmentPackages: 'Shipment Packages',
    vehiclesListing: 'Vehicles Listing',
    vehicleCreating: 'Vehicles Creating',
    vehicleUpdating: 'Vehicles Updating',
    governoratesListing: 'Governorates Listing',
    governorateCreating: 'Governorate Creating',
    governorateUpdating: 'Governorate Updating',
    unassignedPackages: 'Unassigned Packages',
    merchantPackages: 'Merchant Packages',
    supplierPackages: 'Supplier Packages',
    hubPackages: 'Hub Packages',
    shipmentsHistory: 'Shipments History',
    clientName: 'Client Name',
    createdAt: 'Created At',
    createTaxiMeterRole: 'Create New Taxi Meter Role',
    taxiMeter: 'Taxi Meter',
    fromCity: 'From City',
    toCity: 'To City',
    selectShipmentFare: 'Select Shipment Fare',
    selectCommission: 'Select Commission',
    taxiMeterType: 'Taxi Meter Type',
    driverPayments: 'Driver Payments',
    driverDetails: 'Driver Details',
    paymentDetails: 'Payment Details',
    collectPayments: 'Collect Payments',
    backToDriversList: 'Back To Driver List',
    basicInfo: 'Basic Info',
    createNewArea: 'Create New Area',
    createNewDriver: 'Create New Driver',
    createNewOffice: 'Create New Office',
    driverCreating: 'Driver Creating',
    driversListing: 'Drivers Listing',
    driverUpdating: 'Driver Updating',
    roleName: 'Role Name',
    shortDescription: 'Short Description',
    roleGroupTooltip:
      'Available permissions vary based on the selected role group',
    selectGroup: 'Select a Group...',
    updateRole: 'Update Role',
    updateShipmentSize: 'Update Shipment Size',
    updateClient: 'Update Client',
    updateHub: 'Update Hub',
    updateAdmin: 'Update Admin',
    updateVehicle: 'Update Vehicle',
    updateProduct: 'Update Product',
    updateArea: 'Update Area',
    updateGovernorate: 'Update Governorate',
    updateAboutUs: 'Update About Us',
    updateDriver: 'Update Driver',
    updateTaxiMeterRole: 'Update Taxi Meter Role',
    updateTermsAndConditions: 'Update Terms And Conditions',
    taxiMeterUpdating: 'Taxi Meter Updating',
    taxiMeterCreating: 'Taxi Meter Creating',
    taxiMeterListing: 'Taxi Meter Listing',
    areasListing: 'Areas Listing',
    areaCreating: 'Area Creating',
    areaUpdating: 'Area Updating',
    roleGroup: 'Role Group',
    groupsCreating: 'Groups Creating',
    groupsListing: 'Groups Listing',
    groupsUpdating: 'Groups Updating',
    clientCreating: 'Client Creating',
    clientsListing: 'Clients Listing',
    clientUpdating: 'Client Updating',
    members: 'Members',
    assets: 'Assets',
    shipmentSizesListing: 'Shipment Sizes Listing',
    shipmentSizeCreating: 'Shipment Size Creating',
    shipmentSizeUpdating: 'Shipment Size Updating',
    kurdishName: 'Kurdish Name',
    englishName: 'English Name',
    arabicName: 'Arabic Name',
    phoneNumber: 'Phone Number',
    userphoneNumber: 'User Phone Number',
    nameEnLang: 'Name in English Language',
    nameArLang: 'Name in Arabic Language',
    nameKuLang: 'Name in Kurdish Language',
    width: 'Width',
    height: 'Height',
    weight: 'Weight',
    shipmentStatuses: 'Shipment Statuses',
    filterOptions: 'Filter Options',
    governoratesNmae: 'Governorates Name',
    office: 'Office',
    holderName: 'Holder Name',
    areaName: 'Area Name',
    docId: 'Document ID',
    numberOfPackages: 'Number Of Packages',
    notes: 'Notes',
    productImage: 'Product Image',
    arabicDescription: 'Arabic Desciption',
    englishDescription: 'English Description',
    kurdishDescription: 'Kurdish Description',
    descriptionArLang: 'Description in Arabic Language',
    descriptionEnLang: 'Description in English Language',
    descriptionKuLang: 'Description in Kurdish Language',
    textInEnglish: 'Text in English',
    textInKurdish: 'Text in Kurdish',
    textInArabic: 'Text in Arabic',
    adminUsersListing: 'Admin Users Listing',
    adminUsersUpdating: 'Admin Users Updating',
    adminUsersCreating: 'Admin Users Creating',
    backToList: 'Back to List',
    contactUsListing: 'Contact Us Listing',
    contactUsOverview: 'Contact Us Overview',
    hubsListing: 'Hubs Listing',
    hubCreating: 'Hub Creating',
    hubUpdating: 'Hub Updating',
    officesListing: 'Offices Listing',
    officeCreating: 'Office Creating',
    officeUpdating: 'Office Updating',
    unassignedListing: 'Unassigned Listing',
    fullName: 'Full Name',
    fullAdminName: 'Full Admin Name',
    userName: 'User Name',
    role: 'Role',
    adminEmail: 'Admin Email',
    areas: 'Areas',
    noResultsFound: 'No Resut Found',
    profileImage: 'Profile Image',
    licenseImage: 'License Image',
    selectClient: 'Select Client',
    selectOffice: 'Select office',
    selectStatus: 'Select Status',
    selectType: 'Select Type',
    selectPermissions: 'Select Permission . . .',
    selectRole: 'Select Role',
    selectArea: 'Select Area',
    permissions: 'Permissions',
    type: 'Type',
    clients: 'Clients',
    latitude: 'Latitude',
    longitude: 'Longitude',
    saveChanges: 'Save Changes',
    discard: 'Discard',
    address: 'Address',
    usdPrice: 'USD Price',
    iqdPrice: 'IQD Price',
    exchangeRateListing: 'Exchange Rate Listing',
    exchangeRateCreating: 'Exchange Rate Creating',
    exchangeRateUpdating: 'Exchange Rate Updating',
    addNewExchangeRate: 'Add New Exchange Rate',
    exchangeRate: 'Exchange Rate',
    isPrimary: 'Is Primary',
  },
  ar: {
    signInTitle: 'Sign In to LMD Admin',
    signInAppraiser: 'Sign In Appraisers',
    signInClients: 'Sign In Clients',
    email: 'Email',
    emailAddress: 'Email Address',
    password: 'Password',
    enterPassword: 'Enter Password',
    vehicle: 'المركبة',
    title: 'Title',
    continue: 'Continue',
    driverType: 'نوع السائق',
    selectDriver: 'اختر سائق',
    selectVehicle: 'Select Vehicle',
    alternativeLocale: 'LMD is also offered in: {0}',
    alternativeLocaleVal: 'العربية',
    pleaseWait: 'Please wait...',
    tryAgainExcl: 'Try again!',
    areYouSureQ: 'هل انت متأكد؟',
    confirmActive: 'Are you sure you want to activate it',
    confirmInActive: ' Are you sure you want to inactivate it ?',
    selectGovernorate: 'Select Governorate',
    governorates: 'Governorates',
    ROLE_NAME_IS_REQUIRED_FIELD: 'حقل اسم الدور مطلوب',
    ROLE_GROUP_IS_REQUIRED_FIELD: 'حقل مجموعة الدور مطلوب',
    TITLE_IS_REQUIRED_FIELD: 'حقل العنوان مطلوب ',
    AR_TITLE_IS_REQUIRED_FIELD: 'حقل العنوان باللغة العربية مطلوب',
    EN_TITLE_IS_REQUIRED_FIELD: 'حقل العنوان باللغة الانجليزية مطلوب',
    KU_TITLE_IS_REQUIRED_FIELD: 'حقل العنوان باللغة الكردية مطلوب',
    AR_DESCRIPTION_IS_REQUIRED_FIELD: 'حقل الوصف باللغة العربية مطلوب',
    EN_DESCRIPTION_IS_REQUIRED_FIELD: 'حقل الوصف باللغة الانجليزية مطلوب',
    KU_DESCRIPTION_IS_REQUIRED_FIELD: 'حقل الوصف باللغة الكردية مطلوب',
    GOVERNORATE_IS_REQUIRED_FIELD: 'Governorate is a required field',
    DESCRIPTION_IS_REQUIRED_FIELD: 'حقل الوصف مطلوب',
    VEHICLEID_IS_REQUIRED_FIELD: 'Vehicle is a required field',
    USER_NAME_IS_REQUIRED_FIELD: 'User name is a required field',
    PHONE_IS_REQUIRED_FIELD: 'حقل رقم الهاتف مطلوب',
    WIDTH_IS_REQUIRED_FIELD: 'حقل العرض مطلوب',
    HEIGHT_IS_REQUIRED_FIELD: 'حقل الطول مطلوب',
    WEIGHT_IS_REQUIRED_FIELD: 'حقل الوزن مطلوب',
    FULL_NAME_IS_REQUIRED_FIELD: 'حقل الإسم الكامل مطلوب',
    TYPE_USER_IS_REQUIRED_FIELD: 'Type user is a required field',
    PHONE_MIN_11: 'minimum length is 11 Number',
    EMAIL_NOT_VALID: 'البريد الإلكتروني غير صحيح',
    EMAIL_IS_REQUIRED_FIELD: 'حقل البريد الإلكتروني مطلوب',
    PASSWORD_AT_LEAST_ONE_NUMBER:
      'كلمة المرور يجب ان تحتوي على رقم واحد على الاقل',
    PASSWORD_AT_LEAST_ONE_LOWER:
      'كلمة المرور يجب ان تحتوي على حرف صغير واحد على الاقل',
    PASSWORD_AT_LEAST_ONE_UPPER:
      'كلمة المرور يجب ان تحتوي على حرف كبير واحد على الاقل',
    PASSWORD_MIN_4: 'كلمة المرور يجب ان تحتوي على ٤ احرف على الاقل',
    PASSWORD_MAX_20: 'كلمة المرور يجب ان تحتوي على ٢٠ حرف على الاكثر',
    PASSWORD_IS_REQUIRED_FIELD: 'حقل كلمة المرور مطلوب',
    CONFIRM_PASSWORD_IS_REQUIRED_FIELD: 'Confirm Password is a required field',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    AREA_REQUIRED_FIELD: 'Area Required Field',
    ROLE_IS_REQUIRED_FIELD: 'حقل الدور مطلوب',
    SUCCESSFULLY_CREATED_ROLE: 'تم إنشاء دور جديد بنجاح!',
    SUCCESSFULLY_CREATED_SHIPMENT_SIZE: 'تم انشاء حجم شحنة جديد بنجاح!',
    SUCCESSFULLY_CREATED_VEHICLE: 'تم انشاء مركبة جديدة بنجاح!',
    SUCCESSFULLY_UPDATED_VEHICLE: 'تم تحديث المركبة بنجاح!',
    SUCCESSFULLY_CREATED_OFFICE: 'تم انشاء مكتب جديد بنجاح!',
    SUCCESSFULLY_CREATED_HUB: 'تم انشاء محور جديد بنجاح!',
    SUCCESSFULLY_CREATED_EXCHANGE_RATE: 'Successfully created a exchange Rate!',
    SUCCESSFULLY_UPDATED_EXCHANGE_RATE: 'Successfully updated exchange Rate!',
    SUCCESSFULLY_CREATED_PACKAGE: 'Successfully created a new packages!',
    SUCCESSFULLY_UPDATED_PRODUCT: 'Successfully updated product!',
    SUCCESSFULLY_CREATED_PRODUCT: 'Successfully created product!',
    SUCCESSFULLY_CREATED_SHIPMENT: 'تم انشاء شحنة جديدة بنجاح!',
    SUCCESSFULLY_UPDATED_SHIPMENT: 'تم تحديث الشحنة',
    SUCCESSFULLY_UPDATED_OFFICE: 'تم تحديث مكتب بنجاح!',
    SUCCESSFULLY_UPDATED_ROLE: 'تم تحديث الدور بنجاح!',
    SUCCESSFULLY_UPDATED_SHIPMENT_SIZE: 'تم تحديث حجم الشحنة بنجاح!',
    SUCCESSFULLY_CREATED_AREA: 'تم إنشاء منطقة جديدة بنجاح!',
    SUCCESSFULLY_CREATED_ADMIN: 'تم إنشاء ادمن جديد بنجاح!',
    SUCCESSFULLY_CREATED_GROUP: 'تم إنشاء مجموعة جديد بنجاح!',
    SUCCESSFULLY_CREATED_CLIENT: 'تم انشاء عميل جديد بنجاح!',
    SUCCESSFULLY_CREATED_GOVERNORATE: 'تم إنشاء محافظة جديدة بنجاح!',
    SUCCESSFULLY_CREATED_USER: 'تم إنشاء مستخدم جديد بنجاح!',
    SUCCESSFULLY_CREATED_CATEGORY: 'Successfully created a new category!',
    SUCCESSFULLY_UPDATED_CATEGORY: 'Successfully updated category!',
    SUCCESSFULLY_UPDATED_USER: 'تم تحديث مستخدم بنجاح!',
    SUCCESSFULLY_UPDATED_GOVERNORATE: 'تم تحديث محافظة بنجاح!',
    SUCCESSFULLY_UPDATED_CLIENT: 'تم تحديث العميل بنجاح!',
    SUCCESSFULLY_UPDATED_GROUP: 'تم تحديث المجموعة بنجاح!',
    SUCCESSFULLY_UPDATED_ADMIN: 'تم تحديث الادمن بنجاح!',
    SUCCESSFULLY_UPDATED_AREA: 'تم تحديث المنطقة بنجاح!',
    SUCCESSFULLY_UPDATED_HUB: 'تم تحديث المحور بنجاح',
    SUCCESSFULLY_UPDATED_TAXI_METER: 'Successfully updated taxi meter!',
    SUCCESSFULLY_CREATED_TAXI_METER: 'Successfully created a new taxi meter!',
    SUCCESSFULLY_UPDATED_ABOUT_US: 'Successfully updated about us!',
    SUCCESSFULLY_PULLED_PACKAGES: 'Successfully pulled packages!',
    SUCCESSFULLY_CHANGED_STATUSES: 'Successfully Changed Statuses!',
    status: 'الحالة',
    permissionsCount: 'Up to {count} resources',
    mobileNumber: 'رقم الهاتف',
    confirmPassword: 'Confirm Password',
    resetPassword: 'Reset Password',
    tracknumber: 'Tracking Number Segments',
    internalStatus: 'Internal Status',
    externalStatus: 'External Status',
    trackNumber: 'Track Number',
    changeStatus: 'Change Statuses',
    eventDate: 'التاريخ',
    shipmentType: 'نوع السحنة',
    totalProperties: 'Total Properties',
    addNewAdmin: 'اضافة ادمن جديد',
    addNewRole: 'اضافة دور جديد',
    addNewShipmentSize: 'اضافة حجم شحنة جديد',
    addNewShipments: 'اضافة شحنة جديدة',
    addNewGroup: 'اضافة مجموعة جديدة',
    addNewClient: 'اضافة عميل جديد',
    addNewVehicle: 'اضافة مركبة جديدة',
    addNewArea: 'اضافة منطقة جديدة',
    addNewDriver: 'اضافة سائق جديد',
    addNewOffice: 'اضافة مكتب جديد',
    addNewHub: 'اضافة محور جديد',
    addNewGovernorate: 'اضافة محافظة جديدة',
    addNewPackages: 'اضافة حزمة جديدة',
    addNewUser: 'اضافة مستخدم جديد',
    addNewProduct: 'Add New Prodcut',
    addNewTaxiMeter: 'Add New Taxi Meter',
    addNewCategory: 'Add New Category',
    savingShipmentPackagesNote: 'Saving Shipment Packages Note',
    recipientPhoneNumber: 'Recipient Phone Number',
    secondRecipientPhoneNumber: 'Second Recipient Phone Number',
    secondPhoneNumber: 'Second Phone Number',
    shipmentDetails: 'تفاصبل الشحنة',
    auctionDetails: 'Auction Details',
    importPackages: 'Import Packages',
    shipments: 'الشحنات',
    shipmentsListing: 'قائمة الشحنات',
    shipmentCreating: 'اضافة شحنة',
    shipmentUpdating: 'تحديث شحنة',
    merchantListing: 'قائمة التجار',
    rolesListing: 'قائمة الادوار',
    roleCreating: 'انشاء دور جديد',
    roleUpdating: 'تحديث الدور',
    productsListing: 'Products Listing',
    producctCreating: 'Product Creating',
    productUpdating: 'Product Updating',
    usersListing: 'قائمة المستخدمين',
    userCreating: 'انشاء مستخدم',
    userUpdating: 'تحديث مستخدم',
    categoriesListing: 'Categories Listing',
    categoryCreating: 'Category Creating',
    categoryUpdating: 'Category Updating',
    aboutUsListing: 'About Us Listing',
    aboutUsUpdating: 'About Us Updating',
    createNewRole: 'انشاء دور جديد',
    createNewClient: 'انشاء عميل جديد',
    createNewgroup: 'انشاء مجموعة جديدة',
    createNewShipmentSize: 'انشاء حجم شحنة جديد',
    createNewAdmin: 'انشاء ادمن جديد',
    createNewHub: 'انشاء محور جدبد',
    createNewVehicle: 'Create New Vehicle',
    createNewGovernorate: 'انشاء محافظة جديدة',
    createNewUser: 'انشاء مستخدم جديد',
    createNewGroup: 'Create New Group',
    shipmentPackages: 'حزمات الشحنة',
    vehiclesListing: 'قائمة المركبات',
    vehicleCreating: 'انشاء مركبة',
    vehicleUpdating: 'تحديث مركبة',
    createNewArea: 'انشاء منطقة جديدة',
    createNewDriver: 'انشاء سائق جديد',
    createNewOffice: 'انشاء مكتب جديد',
    driverCreating: 'انشاء سائق',
    driversListing: 'قائمة السائقين',
    driverUpdating: 'تحديث السائق',
    governoratesListing: 'قائمة المحافظات',
    governorateCreating: 'انشاء محافظة',
    governorateUpdating: 'تحديث محافظة',
    merchantPackages: 'Merchant Packages',
    supplierPackages: 'Supplier Packages',
    unassignedPackages: 'Unassigned Packages',
    hubPackages: 'Hub Packages',
    shipmentsHistory: 'Shipments History',
    clientName: 'Client Name',
    createdAt: 'Created At',
    driverPayments: 'Driver Payments',
    driverDetails: 'Driver Details',
    createTaxiMeterRole: 'Create New Taxi Meter Role',
    taxiMeter: 'Taxi Meter',
    fromCity: 'From City',
    toCity: 'To City',
    selectShipmentFare: 'Select Shipment Fare',
    selectCommission: 'Select Commission',
    taxiMeterType: 'Taxi Meter Type',
    paymentDetails: 'Payment Details',
    collectPayments: 'Collect Payments',
    backToDriversList: 'Back To Driver List',
    basicInfo: 'Basic Info',
    roleName: 'إسم الدور',
    shortDescription: 'وصف قصير',
    roleGroupTooltip: 'الاذونات المتاحة تختلف من مجموعة الى اخرى',
    selectGroup: 'إختر مجموعة...',
    updateRole: 'تحديث الدور',
    updateShipmentSize: 'تحديث حجم الشحنة',
    updateClient: 'تحديث عميل',
    updateHub: 'تحديث محور',
    updateAdmin: 'تحديث الادمن',
    updateVehicle: 'تحديث المركبة',
    updateProduct: 'Update Product',
    areasListing: 'قائمة المناطق',
    updateArea: 'تحديث المنطقة',
    areaUpdating: 'تحديث منطقة',
    updateGovernorate: 'تحديث محافظة',
    updateDriver: 'تحديث سائق',
    areasCreating: 'انشاء منطقة',
    roleGroup: 'مجموعة الدور',
    groupsCreating: 'تحديث المجموعة',
    groupsListing: 'قائمة المجموعات',
    groupsUpdating: 'تحديث المجموعة',
    clientCreating: 'انشاء عميل',
    clientsListing: 'قائمة العملاء',
    clientUpdating: 'تحديث العميل',
    members: 'الاعضاء',
    assets: 'الاصول',
    shipmentSizesListing: 'قائمة احجام الشحنة',
    shipmentSizeCreating: 'انشاء حجم شحنة',
    shipmentSizeUpdating: 'تحديث حجم الشحنة',
    kurdishName: 'الاسم بالكردية',
    englishName: 'الاسم بالانجليزية',
    arabicName: 'الاسم بالعربية',
    phoneNumber: 'رقم الهاتف',
    userphoneNumber: 'رقم هاتف المستخدم',
    nameEnLang: 'الاسم في اللغة الانجليزية',
    nameArLang: 'الاسم في اللغة العربية',
    nameKuLang: 'الاسم في اللغة الكردية',
    width: 'العرض',
    height: 'الطول',
    weight: 'الوزن',
    shipmentStatuses: 'حالة الشحنات',
    office: 'Office',
    holderName: 'Holder Name',
    areaName: 'اسم المنطقة',
    docId: 'Doc Id',
    notes: 'ملاحظات',
    productImage: 'Product Image',
    arabicDescription: 'الوصف بالعربية',
    englishDescription: 'الوصف بالانجليزية',
    kurdishDescription: 'الوصف بالكردية',
    descriptionArLang: 'الوصف في اللغة العربية',
    descriptionEnLang: 'الوصف في اللغة الانجليزية',
    descriptionKuLang: 'الوصف في اللغة الكردية',
    textInEnglish: 'Text in English',
    textInKurdish: 'Text in Kurdish',
    textInArabic: 'Text in Arabic',
    adminUsersListing: 'قائمة الاعضاء الادمن',
    adminUsersCreating: 'انشاء ادمن جديد',
    adminUsersUpdating: 'تحديث الادمن',
    contactUsListing: 'Contact Us Listing',
    contactUsOverview: 'Contact Us Overview',
    backToList: 'الرجوع الى القائمة',
    hubsListing: 'قائمة المحاور',
    hubCreating: 'انشاء محور',
    hubUpdating: 'تحديث محور',
    officesLisitng: 'قائمة المكاتب ',
    officeCreating: 'انشاء مكتب',
    officeUpdating: 'تحديث مكتب',
    unassignedListing: 'Unassigned Listing',
    fullName: 'الاسم بالكامل',
    fullAdminName: 'اسم الادمن الكامل',
    userName: 'User Name',
    role: 'الدور',
    adminEmail: 'البريد الإلكتروني للادمن',
    areas: 'المناطق',
    noResultsFound: 'لا يوجد نتيجة',
    profileImage: 'صورة شخصية',
    licenseImage: 'صورة هوية',
    selectClient: 'Select Client',
    selectOffice: 'Select office',
    selectStatus: 'Select Status',
    selectType: 'Select Type',
    selectPermissions: 'Select Permission . . .',
    selectRole: 'Select Role',
    selectArea: 'تحديد منطقة',
    permissions: 'Permissions',
    type: 'Type',
    clients: 'العملاء',
    latitude: 'خط العرض',
    longitude: 'خط الطول',
    saveChanges: 'Save Changes',
    discard: 'Discard',
    address: 'Address',
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
