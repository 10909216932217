import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface Shipment {
  guideVideo: string;
  eventDateTime: string;
  venueEn: string;
  venueAr: string;
  descriptionEn: string;
  descriptionAr: string;
  sizes: string;
  additionalInfoEn: string;
  additionalInfoAr: string;
  organiserName: string;
  venue: string;
  id: string;
  organiserId: number;
  propertiesListedNum: number;
  propertiesSoldNum: number;
  propertiesNotSoldNum: number;
  totalSale: number;
  shipmentPropertysCount: number;
  organisersLocationsId: number | null;
  latitude: number | null;
  longitude: number | null;
  trackingnumbersegments: string;
  isActive: boolean;
  shipmentType: string;
  status: string;
  tracknumber: string;
  statusId: string;
  isEditable: boolean;
  officeId: string;
  driverId: string;
}

export interface Packages {
  id: string;
  address: string;
  recipientPhoneNumber: string;
  secondRecipientPhoneNumber?: string | null;
  docId: string;
  holderName: string;
  qrCodeImage: string;
  trackNumber: string;
  notes: string;
  areaId?: string;
}

export interface InvalidPackages {
  docId: number;
  areaId: string;
  holderName: string;
  address: string;
  recipientPhoneNumber: string;
  area: string;
}

export interface PackageData {
  invalidPackages: InvalidPackages[];
  invalidPackagesCount: number;
  sucssesPackagesCount: number;
  // other properties...
}
export interface ShipmentsFilterListInfo {
  officeId: string;
  status: string;
  trackNumber: string;
  packageTrackNumber: string;
  phoneNumber: string;
  docId: string;
  name: string;
  driverName: string;
  packageStatusId: string;
  areaId: string[];
  governorateId: string;
  startDate: string;
  endDate: string;
  hubId: any;
}

export interface ShipmentStatus {
  id: number;
  title: string;
  isActive: boolean;
}
export interface ShipmentOrganasie {
  id: number;
  titleEn: string;
  titleAr: string;
  phone: string;
  is_active: boolean;
  logo: string;
}

export interface ShipmentsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  type: string;
  isNext: boolean;
  previous: number;
  shipments: Array<Shipment>;
  errors: unknown;
}

@Module
export default class ShipmentsModule
  extends VuexModule
  implements ShipmentsListInfo
{
  shipments = [] as Array<Shipment>;
  shipmentProperties = [] as Array<Packages>;
  shipmentStatuses = [] as Array<any>;
  shipmentOrganises = [] as Array<ShipmentOrganasie>;
  shipmentFilter = {} as ShipmentsFilterListInfo;
  gwOfficesListData = [] as Array<any>;
  hubAreasData = [] as Array<any>;
  exportShipments = [] as Array<Shipment>;
  totalItems = 0;
  currentPage = 1;
  page = 1;
  type = '';
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get shipments list
   * @returns Array<Shipment>
   */
  get shipmentsList(): Array<Shipment> {
    return this.shipments;
  }

  /**
   * Get total items
   * @returns number
   */
  get shipmentsListCount(): number {
    return this.totalItems;
  }

  /**
   * Get shipment properties list
   * @returns array
   */
  get shipmentPackagesList(): Array<Packages> {
    return this.shipmentProperties;
  }

  get ShipmentPackagesListCount(): number {
    return this.totalItems;
  }

  /**
   * Get gwOfficesList
   * @returns Array<any>
   */
  get gwOfficesList(): Array<any> {
    return this.gwOfficesListData;
  }

  get getAllAreasList(): Array<any> {
    return this.hubAreasData;
  }

  /**
   * Get shipment statuses list
   * @returns array
   */
  get shipmentStatusesList(): Array<ShipmentStatus> {
    return this.shipmentStatuses;
  }

  get shipmentOrganisesList(): Array<ShipmentOrganasie> {
    return this.shipmentOrganises;
  }

  get exportShipmentsList(): Array<Shipment> {
    return this.exportShipments;
  }

  @Mutation
  [Mutations.SET_SHIPMENTS](data) {
    this.shipments = data.data;
    this.totalItems = data.meta.itemCount;
    this.currentPage = data.meta.page;
    this.pageSize = data.meta.take;
    this.isNext = data.meta.hasNextPage;
    this.previous = data.meta.page;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SHIPMENT_PROPERTIES](data) {
    this.shipmentProperties = data.data;
    this.totalItems = data.meta.itemCount;
    this.page = data.meta.page;
    this.pageSize = data.meta.take;
    this.isNext = data.meta.hasNextPage;
    this.previous = data.meta.page;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_SHIPMENT_GW_OFFICES](data) {
    this.gwOfficesListData = data.data;
  }

  @Mutation
  [Mutations.SET_SHIPMENT_STATUSES](data) {
    this.shipmentStatuses = data.data;
  }

  @Mutation
  [Mutations.SET_SHIPMENT_ORGANISE](data) {
    this.shipmentOrganises = data.data;
  }

  @Mutation
  [Mutations.SET_SHIPMENTS_AREAS](data) {
    this.hubAreasData = data.data;
  }

  @Mutation
  [Mutations.SET_SHIPMENTS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.SET_SHIPMENTS_PROPERTY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SHIPMENTS_LIST]() {
    this.shipments = [] as Array<Shipment>;
    this.type = '';
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SHIPMENTS_FILTER](obj) {
    this.shipmentFilter.officeId = String(obj.officeId);
    this.shipmentFilter.status = String(obj.status);
    this.shipmentFilter.trackNumber = String(obj.trackNumber);
    this.shipmentFilter.areaId = obj.areaId;
    this.shipmentFilter.docId = obj.docId;
    this.shipmentFilter.driverName = obj.driverName;
    this.shipmentFilter.governorateId = obj.governorateId;
    this.shipmentFilter.startDate = obj.startDate;
    this.shipmentFilter.endDate = obj.endDate;
    this.shipmentFilter.hubId = obj.hubId;
    this.shipmentFilter.name = obj.name;
    this.shipmentFilter.packageTrackNumber = obj.packageTrackNumber;
    this.shipmentFilter.phoneNumber = obj.phoneNumber;
    this.shipmentFilter.packageStatusId = obj.packageStatusId;
  }

  @Mutation
  [Mutations.SET_EXPORT_SHIPMENTS](data) {
    this.exportShipments = data.data;
  }

  @Action
  [Actions.RESET_SHIPMENTS_STORE]() {
    this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
  }

  @Action
  [Actions.UPDATE_SHIPMENTS_FILTER](val) {
    this.context.commit(Mutations.SET_SHIPMENTS_FILTER, val);
  }

  @Action
  [Actions.DELETE_SHIPMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('shipments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SHIPMENT_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return (
      ApiService.delete('packages', id)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            [response.statusText]: [response.data.statusText],
          });
        })
    );
  }

  @Action
  [Actions.CREATE_SHIPMENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/shipments', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CHANGE_STATUS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.put('/shipments/status/packages', data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CREATE_SHIPMENT_PACKAGE]({ data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/shipments/packages`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CREATE_PACKAGES]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/packages/${id}`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SHIPMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/shipments', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SHIPMENT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SHIPMENTS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_SHIPMENTS_PROPERTY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SHIPMENTS_PROPERTY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SHIPMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('shipments', id);
  }

  @Action
  [Actions.GET_ALL_SHIPMENT_STATUSES](type) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/statuses/all', type)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SHIPMENT_STATUSES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SHIPMENT_ORGANISERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/shipments-organisers/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SHIPMENT_ORGANISE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_SHIPMENT_PROPERTIES](shipmentId) {
    const params = {
      page: this.page,
      take: 50,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/getByShipment/${shipmentId}`, { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SHIPMENT_PROPERTIES, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_SHIPMENT_PROPERTIES](shipmentId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/packages/getAllByShipment/${shipmentId}`)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_SUPPLIER_SHIPMENTS](input) {
    ApiService.setAuthorizationHeader();
    const params: Record<string, any> = {
      page: this.currentPage,
      take: 100,
      officeId: this.shipmentFilter.officeId,
      trackNumber: this.shipmentFilter.trackNumber,
      packageTrackNumber: this.shipmentFilter.packageTrackNumber,
      phoneNumber: this.shipmentFilter.phoneNumber,
      docId: this.shipmentFilter.docId,
      name: this.shipmentFilter.name,
      driverName: this.shipmentFilter.driverName,
      packageStatusId: this.shipmentFilter.packageStatusId,
      areaId: this.shipmentFilter.areaId,
      governorateId: this.shipmentFilter.governorateId,
      startDate: this.shipmentFilter.startDate,
      endDate: this.shipmentFilter.endDate,
      hubId: this.shipmentFilter.hubId,
      shipmentType: 'supplier',
    };
    if (
      this.shipmentFilter.status !== undefined &&
      this.shipmentFilter.status !== ''
    ) {
      params.status = this.shipmentFilter.status;
    }
    return ApiService.query('/shipments', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          const enDate = new Date(r.createdAt).toLocaleString('en-GB');
          r.eventDate = enDate;
          return r;
        });
        this.context.commit(Mutations.SET_SHIPMENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_MERCHANT_SHIPMENTS](type) {
    ApiService.setAuthorizationHeader();
    const params: Record<string, any> = {
      page: this.currentPage,
      take: 100,
      officeId: this.shipmentFilter.officeId,
      trackNumber: this.shipmentFilter.trackNumber,
      packageTrackNumber: this.shipmentFilter.packageTrackNumber,
      phoneNumber: this.shipmentFilter.phoneNumber,
      docId: this.shipmentFilter.docId,
      name: this.shipmentFilter.name,
      driverName: this.shipmentFilter.driverName,
      packageStatusId: this.shipmentFilter.packageStatusId,
      areaId: this.shipmentFilter.areaId,
      governorateId: this.shipmentFilter.governorateId,
      startDate: this.shipmentFilter.startDate,
      endDate: this.shipmentFilter.endDate,
      shipmentType: 'merchant',
      hubId: this.shipmentFilter.hubId,
    };
    if (
      this.shipmentFilter.status !== undefined &&
      this.shipmentFilter.status !== ''
    ) {
      params.status = this.shipmentFilter.status;
    }
    return ApiService.query('/shipments', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          const enDate = new Date(r.createdAt).toLocaleString('en-GB');
          r.eventDate = enDate;
          return r;
        });
        this.context.commit(Mutations.SET_SHIPMENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_SHIPMENT_GW_OFFICES](clientId) {
    const params = {
      clientId: clientId,
      includeDeleted: true,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/offices/all/all`, { params })
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          return {
            titleTranslate: i.name?.find((r) => r.languageCode === 'en')?.title,
            id: i.id,
            deletedat: i.deletedat,
            driverId: i.driverId,
          };
        });
        this.context.commit(Mutations.SET_SHIPMENT_GW_OFFICES, data);
        return data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.EXPORT_SHIPMENTS](type) {
    const params: Record<string, any> = {
      page: this.currentPage,
      officeId: this.shipmentFilter.officeId,
      trackNumber: this.shipmentFilter.trackNumber,
      packageTrackNumber: this.shipmentFilter.packageTrackNumber,
      phoneNumber: this.shipmentFilter.phoneNumber,
      docId: this.shipmentFilter.docId,
      name: this.shipmentFilter.name,
      driverName: this.shipmentFilter.driverName,
      packageStatusId: this.shipmentFilter.packageStatusId,
      areaId: this.shipmentFilter.areaId,
      governorateId: this.shipmentFilter.governorateId,
      startDate: this.shipmentFilter.startDate,
      endDate: this.shipmentFilter.endDate,
      shipmentType: type,
    };
    if (
      this.shipmentFilter.status !== undefined &&
      this.shipmentFilter.status !== ''
    ) {
      params.status = this.shipmentFilter.status;
    }
    ApiService.setAuthorizationHeader();
    return ApiService.query('/shipments/export', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EXPORT_SHIPMENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.IMPORT_PACKAGES]({ data, shipmentId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/packages/import/${shipmentId}`, data)
      .then(({ data }) => {
        if (!data || !data.data || !data.data.invalidPackages) {
          return [];
        }
        const mappedData = data.data.invalidPackages.map((obj) => {
          return obj;
        });
        return {
          invalidPackages: data.data.invalidPackages,
          invalidPackagesCount: data.data.invalidPackagesCount,
          sucssesPackagesCount: data.data.sucssesPackagesCount,
        };
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }

  @Action
  [Actions.UPDATE_SHIPMENTS_PACKAGE]({ data, shipmentId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/shipments/packages', shipmentId, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_SHIPMENT_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/packages', id);
  }

  @Action
  [Actions.GET_SHIPMENT_AREAS](id) {
    const params = {
      governorateId: id,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/areas/all`, { params })
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          return {
            titleTranslate: i.title.find((r) => r.languageCode === 'en')?.title,
            id: i.id,
          };
        });
        this.context.commit(Mutations.SET_SHIPMENTS_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
