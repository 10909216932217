import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface PackageObject {
  id: number;
  isactive: boolean;
  title: string;
  size: string;
  shipmentTrackNumber: string;
  trackNumber: string;
  name: string;
  recipientPhoneNumber: string;
  areaName: string;
  passportNumber: string;
  officeName: string;
  driverName: string;
  createdAt: string;
  status: string;
}

export interface PackagesFilterListInfo {
  name: string;
  phone: string;
  driverName: string;
  trackNumber: string;
  docId: string;
  originType: string;
  statusId: string;
  areaName: string;
  areaId: any;
  governorateId: string;
  startDate: string;
  endDate: string;
  shipmentTrackNumber: string;
  officeName: string;
  hubId: any;
}

export interface packagesListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  packages: Array<PackageObject>;
  errors: unknown;
}

@Module
export default class PackagesModule
  extends VuexModule
  implements packagesListInfo
{
  packages = [] as Array<PackageObject>;
  packagesFilter = {} as PackagesFilterListInfo;
  exportPackages = [] as Array<PackageObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get packages list
   * @returns Array<PackageObject>
   */
  get packagesList(): Array<PackageObject> {
    return this.packages;
  }

  /**
   * Get total items
   * @returns number
   */
  get packagesListCount(): number {
    return this.itemCount;
  }

  get exportPackagesList(): Array<PackageObject> {
    return this.exportPackages;
  }

  @Mutation
  [Mutations.SET_PACKAGES](data) {
    this.packages = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PACKAGES_FILTER](obj) {
    this.packagesFilter.name = String(obj.name);
    this.packagesFilter.phone = obj.phone;
    this.packagesFilter.trackNumber = obj.trackNumber;
    this.packagesFilter.driverName = obj.driverName;
    this.packagesFilter.docId = obj.docId;
    this.packagesFilter.statusId = obj.statusId;
    this.packagesFilter.originType = obj.originType;
    this.packagesFilter.areaName = obj.areaName;
    this.packagesFilter.areaId = obj.areaId;
    this.packagesFilter.governorateId = obj.governorateId;
    this.packagesFilter.startDate = obj.startDate;
    this.packagesFilter.endDate = obj.endDate;
    this.packagesFilter.hubId = obj.hubId;
    this.packagesFilter.officeName = obj.officeName;
    this.packagesFilter.shipmentTrackNumber = obj.shipmentTrackNumber;
  }

  @Mutation
  [Mutations.SET_PACKAGES_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_PACKAGES_LIST]() {
    this.packages = [] as Array<PackageObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_EXPORT_PACKAGES](data) {
    this.exportPackages = data.data;
  }

  @Action
  [Actions.EXPORT_PACKAGES](packagetype) {
    console.log(packagetype);
    const params: {
      page: number;
      type: string;
      driverName?: string;
      mobile?: string;
      docId?: string;
      name?: string;
      trackNumber?: string;
      statusId?: string;
      areaName?: string;
      originType?: string;
      areaId?: string;
      governorateId?: string;
      startDate?: string;
      endDate?: string;
      shipmentTrackNumber?: string;
      officeName?: string;
      hubId?: string;
    } = {
      page: this.page,
      type: packagetype,
      driverName: this.packagesFilter.driverName,
      mobile: this.packagesFilter.phone,
      docId: this.packagesFilter.docId,
      name: this.packagesFilter.name,
      trackNumber: this.packagesFilter.trackNumber,
      statusId: this.packagesFilter.statusId,
      areaName: this.packagesFilter.areaName,
      areaId: this.packagesFilter.areaId,
      governorateId: this.packagesFilter.governorateId,
      startDate: this.packagesFilter.startDate,
      endDate: this.packagesFilter.endDate,
      shipmentTrackNumber: this.packagesFilter.shipmentTrackNumber,
      officeName: this.packagesFilter.officeName,
      hubId: this.packagesFilter.hubId,
    };
    if (packagetype === 'hub' && this.packagesFilter.originType) {
      params.originType = this.packagesFilter.originType;
    }
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/export`, { params })
      .then(({ data }) => {
        data.data = data?.data.map((el) => ({
          ...el,
          internalStatus: el.status.internal,
        }));
        this.context.commit(Mutations.SET_EXPORT_PACKAGES, data);
        return data.data;
      })

      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.EXPORT_PACKAGES_HUB_SHEET](packagetype) {
    const params = {
      page: this.page,
      type: 'hub',
      shipmentTrackNumber: packagetype,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/export`, { params })
      .then(({ data }) => {
        data.data = data?.data.map((el) => ({
          ...el,
          internalStatus: el.status.internal,
        }));
        this.context.commit(Mutations.SET_EXPORT_PACKAGES, data);
        return data.data;
      })

      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.EXPORT_PACKAGES_MERCHANT_SHEET](packagetype) {
    const params = {
      page: this.page,
      type: 'merchant',
      shipmentTrackNumber: packagetype,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/export`, { params })
      .then(({ data }) => {
        data.data = data?.data.map((el) => ({
          ...el,
          internalStatus: el.status.internal,
        }));
        this.context.commit(Mutations.SET_EXPORT_PACKAGES, data);
        return data.data;
      })

      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.EXPORT_PACKAGES_SUPPLIER_SHEET](packagetype) {
    const params = {
      page: this.page,
      type: 'supplier',
      shipmentTrackNumber: packagetype,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/export`, { params })
      .then(({ data }) => {
        data.data = data?.data.map((el) => ({
          ...el,
          internalStatus: el.status.internal,
        }));
        this.context.commit(Mutations.SET_EXPORT_PACKAGES, data);
        return data.data;
      })

      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.UPDATE_PACKAGES_FILTER](val) {
    this.context.commit(Mutations.SET_PACKAGES_FILTER, val);
  }

  @Action
  [Actions.RESET_PACKAGES_STORE]() {
    this.context.commit(Mutations.RESET_PACKAGES_LIST);
  }

  @Action
  [Actions.DELETE_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/packages', id)
      .then(() => {
        this.context.commit(Mutations.RESET_PACKAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_HUB_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/packages/hub', id)
      .then(() => {
        this.context.commit(Mutations.RESET_PACKAGES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_PACKAGE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PACKAGES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/packages', id);
  }

  @Action
  [Actions.GET_PACKAGES](input) {
    const params: {
      page: number;
      type: string;
      take: number;
      driverName?: string;
      mobile?: string;
      docId?: string;
      name?: string;
      trackNumber?: string;
      statusId?: string;
      areaName?: string;
      originType?: string;
      areaId?: string;
      governorateId?: string;
      startDate?: string;
      endDate?: string;
      shipmentTrackNumber?: string;
      officeName?: string;
      hubId?: string;
    } = {
      page: this.page,
      type: input,
      take: 100,
      driverName: this.packagesFilter.driverName,
      mobile: this.packagesFilter.phone,
      docId: this.packagesFilter.docId,
      name: this.packagesFilter.name,
      trackNumber: this.packagesFilter.trackNumber,
      statusId: this.packagesFilter.statusId,
      areaName: this.packagesFilter.areaName,
      areaId: this.packagesFilter.areaId,
      governorateId: this.packagesFilter.governorateId,
      startDate: this.packagesFilter.startDate,
      endDate: this.packagesFilter.endDate,
      shipmentTrackNumber: this.packagesFilter.shipmentTrackNumber,
      officeName: this.packagesFilter.officeName,
      hubId: this.packagesFilter.hubId,
    };
    if (input === 'hub' && this.packagesFilter.originType) {
      params.originType = this.packagesFilter.originType;
    }
    ApiService.setAuthorizationHeader();
    return ApiService.query('/packages', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_PACKAGES, data);
        return data.data.length;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
