import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface AreasObject {
  id: string;
  governorateId: string;
  title: Array<any>;
  description: Array<any>;
  longitude: string;
  latitude: string;
}

export interface AreasListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  areas: Array<AreasObject>;
  errors: unknown;
}

export interface AreaFilterInfo {
  areaName: string;
}

@Module
export default class AreasModule extends VuexModule implements AreasListInfo {
  areas = [] as Array<AreasObject>;
  areasFilter = {} as AreaFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get areas list
   * @returns Array<AreasObject>
   */
  get areasList(): Array<AreasObject> {
    return this.areas;
  }

  /**
   * Get total items
   * @returns number
   */
  get areasListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_AREAS](data) {
    this.areas = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AREA_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_AREAS_LIST]() {
    this.areas = [] as Array<AreasObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AREA_FILTER](obj) {
    this.areasFilter.areaName = obj.areaName;
  }

  @Action
  [Actions.RESET_AREAS_STORE]() {
    this.context.commit(Mutations.RESET_AREAS_LIST);
  }

  @Action
  [Actions.CREATE_AREA](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/areas', data)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/areas', id)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_AREA]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/areas', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_AREAS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_AREA_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_AREA_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_AREA](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/areas', id);
  }

  @Action
  [Actions.UPDATE_AREA_FILTER](val) {
    this.context.commit(Mutations.SET_AREA_FILTER, val);
  }

  @Action
  [Actions.GET_AREAS](obj) {
    const params = {
      page: this.page,
      areaName: this.areasFilter.areaName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/areas', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
