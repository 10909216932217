import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ClientObject {
  id: number;
  phone: number;
  phoneNumber: number;
  email: string;
  type: string;
  fullname: string;
  slug: string;
  isActive: boolean;
  title: Array<any>;
}

export interface ClientListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  clients: Array<ClientObject>;
  errors: unknown;
}

export interface ClientFilterInfo {
  clientName: string;
}
@Module
export default class ClientsModule
  extends VuexModule
  implements ClientListInfo
{
  clients = [] as Array<ClientObject>;
  allClients = [];
  clientsFilter = {} as ClientFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get clients list
   * @returns Array<ClientObject>
   */
  get clientsList(): Array<ClientObject> {
    return this.clients;
  }

  get allClientsList(): Array<ClientObject> {
    return this.allClients;
  }

  /**
   * Get total items
   * @returns number
   */
  get clientsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_ALL_CLIENTS](data) {
    this.allClients = data.data;
  }

  @Mutation
  [Mutations.SET_CLIENTS_FILTER](obj) {
    this.clientsFilter.clientName = obj.clientName;
  }

  @Mutation
  [Mutations.SET_CLIENTS](data) {
    this.clients = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CLIENTS_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_CLIENTS_LIST]() {
    this.clients = [] as Array<ClientObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_CLIENTS_STORE]() {
    this.context.commit(Mutations.RESET_CLIENTS_LIST);
  }

  @Action
  [Actions.UPDATE_CLIENTS_FILTER](val) {
    this.context.commit(Mutations.SET_CLIENTS_FILTER, val);
  }

  @Action
  [Actions.CREATE_CLIENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/clients', data)
      .then(() => {
        this.context.commit(Mutations.RESET_CLIENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_CLIENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/clients', id)
      .then(() => {
        this.context.commit(Mutations.RESET_CLIENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CLIENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/clients', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_CLIENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_CLIENT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_CLIENTS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CLIENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/clients', id);
  }

  @Action
  [Actions.GET_CLIENTS](input) {
    const params = {
      page: this.page,
      clientName: this.clientsFilter.clientName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/clients', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_CLIENTS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/clients/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_CLIENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
