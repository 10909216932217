import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface GovernoratesObject {
  id: string;
  title: Array<any>;
  description: Array<any>;
}

export interface GovernoratesListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  governorates: Array<GovernoratesObject>;
  errors: unknown;
}

export interface GovernoratesFilterInfo {
  governorateName: string;
}

@Module
export default class GovernoratesModule
  extends VuexModule
  implements GovernoratesListInfo
{
  governorates = [] as Array<GovernoratesObject>;
  allGovernorates = [] as Array<GovernoratesObject>;
  governoratesFilter = {} as GovernoratesFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get governorates list
   * @returns Array<GovernoratesObject>
   */
  get governoratesList(): Array<GovernoratesObject> {
    return this.governorates;
  }

  get allGovernoratesList(): Array<GovernoratesObject> {
    return this.allGovernorates;
  }

  /**
   * Get total items
   * @returns number
   */
  get governoratesListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_GOVERNORATE](data) {
    this.governorates = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_GOVERNORATES](data) {
    this.allGovernorates = data.data;
  }

  @Mutation
  [Mutations.SET_GOVERNORATE_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_GOVERNORATES_LIST]() {
    this.governorates = [] as Array<GovernoratesObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_GOVERNORATES_FILTER](obj) {
    this.governoratesFilter.governorateName = obj.governorateName;
  }

  @Action
  [Actions.RESET_GOVERNORATES_STORE]() {
    this.context.commit(Mutations.RESET_GOVERNORATES_LIST);
  }

  @Action
  [Actions.UPDATE_GOVERNORATES_FILTER](val) {
    this.context.commit(Mutations.SET_GOVERNORATES_FILTER, val);
  }

  @Action
  [Actions.CREATE_GOVERNORATE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/governorates', data)
      .then(() => {
        this.context.commit(Mutations.RESET_GOVERNORATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_GOVERNORATE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/governorates', id)
      .then(() => {
        this.context.commit(Mutations.RESET_GOVERNORATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GOVERNORATE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/governorates', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_GOVERNORATES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_GOVERNORATE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_GOVERNORATE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_ONE_GOVERNORATE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/governorates', id);
  }

  @Action
  [Actions.GET_GOVERNORATES]() {
    const params = {
      page: this.page,
      governorateName: this.governoratesFilter.governorateName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/governorates', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GOVERNORATE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_GOVERNORATES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/governorates/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_GOVERNORATES, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
