import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface OfficeObject {
  id: number;
  phone: number;
  mobile: number;
  email: string;
  type: string;
  fullname: string;
  slug: string;
  isActive: boolean;
  name: Array<any>;
  longitude: string;
  latitude: string;
}

export interface OfficeListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  offices: Array<OfficeObject>;
  errors: unknown;
}

export interface OfficeFilterInfo {
  officeName: string;
}

@Module
export default class OfficesModule
  extends VuexModule
  implements OfficeListInfo
{
  offices = [] as Array<OfficeObject>;
  allOffices = [];
  officesFilter = {} as OfficeFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get offices list
   * @returns Array<OfficeObject>
   */
  get officesList(): Array<OfficeObject> {
    return this.offices;
  }

  get allOfficesList(): Array<OfficeObject> {
    return this.allOffices;
  }

  /**
   * Get total items
   * @returns number
   */
  get officesListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_OFFICES](data) {
    this.offices = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_OFFICES_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.SET_ALL_OFFICES](data) {
    this.allOffices = data.data;
  }

  @Mutation
  [Mutations.RESET_OFFICES_LIST]() {
    this.offices = [] as Array<OfficeObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_OFFICES_FILTER](obj) {
    this.officesFilter.officeName = obj.officeName;
  }

  @Action
  [Actions.RESET_OFFICES_STORE]() {
    this.context.commit(Mutations.RESET_OFFICES_LIST);
  }

  @Action
  [Actions.CREATE_OFFICE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/offices', data)
      .then(() => {
        this.context.commit(Mutations.RESET_OFFICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_OFFICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/offices', id)
      .then(() => {
        this.context.commit(Mutations.RESET_OFFICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_OFFICE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/offices', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_OFFICES_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_OFFICES_FILTER](val) {
    this.context.commit(Mutations.SET_OFFICES_FILTER, val);
  }

  @Action
  [Actions.UPDATE_OFFICE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_OFFICES_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_OFFICE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/offices', id);
  }

  @Action
  [Actions.GET_OFFICES](input) {
    const params = {
      page: this.page,
      officeName: this.officesFilter.officeName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/offices', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_OFFICES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_OFFICES](obj) {
    ApiService.setAuthorizationHeader();
    const params = {
      type: obj && obj.driverId ? obj.driverId : 'all',
    };
    return ApiService.query('/offices/all', { params })
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          return { titleTranslate: i.name[0].title, id: i.id };
        });
        this.context.commit(Mutations.SET_ALL_OFFICES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
