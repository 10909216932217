import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface TermsAndConditionsObject {
  id: number;
  htmlAr: string;
  htmlEn: string;
  htmlKu: string;
  type: string;
}

export interface SiteSettings {
  termsAndConditions: Array<TermsAndConditionsObject>;
  errors: unknown;
}
@Module
export default class SiteSettingsModule
  extends VuexModule
  implements SiteSettings
{
  termsAndConditions = [] as Array<TermsAndConditionsObject>;
  errors = {};

  /**
   * Get About list
   * @returns Array<AboutUsObject>
   */

  get TermsAndConditionsView(): Array<TermsAndConditionsObject> {
    return this.termsAndConditions;
  }

  @Mutation
  [Mutations.RESET_TERMS_AND_CONDITIONS]() {
    this.termsAndConditions = [] as Array<TermsAndConditionsObject>;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_TERMS_AND_CONDITIONS](data) {
    this.termsAndConditions = data.data;
    this.errors = {};
  }

  @Action
  [Actions.GET_TERMS_AND_CONDITIONS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/terms-conditions')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TERMS_AND_CONDITIONS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_TERMS_AND_CONDITIONS_BY_ID](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/terms-conditions', id);
  }

  @Action
  [Actions.UPDATE_TERMS_AND_CONDITIONS]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/terms-conditions', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_TERMS_AND_CONDITIONS);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.message],
        });
      });
  }
}
