import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface HubsObject {
  longitude: string;
  latitude: string;
  id: string;
  mobile: string;
  name: Array<any>;
}

export interface HubsListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  hubs: Array<HubsObject>;
  errors: unknown;
}
export interface HubsFilterInfo {
  hubName: string;
}

@Module
export default class HubsModule extends VuexModule implements HubsListInfo {
  hubs = [] as Array<HubsObject>;
  allHubs = [];
  hubsFilter = {} as HubsFilterInfo;
  hubId = [] as Array<HubsObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get HUBS list
   * @returns Array<HubsObject>
   */
  get hubsList(): Array<HubsObject> {
    return this.hubs;
  }

  get allHubsList(): Array<HubsObject> {
    return this.allHubs;
  }

  /**
   * Get total items
   * @returns number
   */
  get hubsListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_HUBS](data: {
    data: HubsObject[];
    meta: {
      page: number;
      take: number;
      itemCount: number;
      pageCount: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  }) {
    this.hubs = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HUB_CURRENT_PAGE](page: number) {
    this.page = page;
  }

  @Mutation
  [Mutations.SET_ALL_HUBS](data: { data: HubsObject[] }) {
    this.hubs = data.data;
  }

  @Mutation
  [Mutations.RESET_HUBS_LIST]() {
    this.hubs = [] as Array<HubsObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HUBS_FILTER](obj) {
    this.hubsFilter.hubName = obj.hubName;
  }

  @Action
  [Actions.RESET_HUBS_STORE]() {
    this.context.commit(Mutations.RESET_HUBS_LIST);
  }

  @Action
  [Actions.CREATE_HUB](data: AxiosRequestConfig) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/hubs', data)
      .then(() => {
        this.context.commit(Mutations.RESET_HUBS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_HUB](id: string) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/hubs', id)
      .then(() => {
        this.context.commit(Mutations.RESET_HUBS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HUB]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/hubs', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_HUBS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HUB_CURRENT_PAGE](val: any) {
    this.context.commit(Mutations.SET_HUB_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_HUB](id: string | undefined) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/hubs', id);
  }

  @Action
  [Actions.UPDATE_HUBS_FILTER](val) {
    this.context.commit(Mutations.SET_HUBS_FILTER, val);
  }

  @Action
  [Actions.GET_HUBS]() {
    const params = {
      page: this.page,
      hubName: this.hubsFilter.hubName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/hubs', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HUBS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HUBS](hub) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/hubs/all')
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          const titleObj = i.name.find(
            (nameObj) => nameObj.languageCode === 'en'
          );
          const titleTranslate = titleObj ? titleObj.title : '';
          return { titleTranslate, id: i.id };
        });
        data.data.push({ titleTranslate: hub.name, id: hub.id });
        this.context.commit(Mutations.SET_ALL_HUBS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HUBS_CREATING]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/hubs/all')
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          const titleObj = i.name.find(
            (nameObj) => nameObj.languageCode === 'en'
          );
          const titleTranslate = titleObj ? titleObj.title : '';
          return { titleTranslate, id: i.id };
        });
        this.context.commit(Mutations.SET_ALL_HUBS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
