import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ContactUsObject {
  id: number;
  name: string;
  email: string;
  phone: string;
  message: string;
  attachments: string;
}
export interface ContactusListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  contacts: Array<ContactUsObject>;
  errors: unknown;
}
@Module
export default class ContactusModule
  extends VuexModule
  implements ContactusListInfo
{
  contacts = [] as Array<ContactUsObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get Contact list
   * @returns Array<BankObject>
   */
  get contactList(): Array<ContactUsObject> {
    return this.contacts;
  }

  /**
   * Get total items
   * @returns number
   */
  get contactListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CONTACTS](data) {
    this.contacts = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CONTACT_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Action
  [Actions.UPDATE_CONTACT_CURRENT_PAGE](val) {
    // UPDATE_CONTACT_CURRENT_PAGE
    this.context.commit(Mutations.SET_CONTACT_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_CONTACT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/contact-us', id);
  }

  @Action
  [Actions.GET_CONTACTS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/contact-us', {
      params: { page: this.page },
    })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          r.createdAt = new Date(r.createdAt)
            .toLocaleString('en-GB')
            .split(',')[0];
          return r;
        });
        this.context.commit(Mutations.SET_CONTACTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
