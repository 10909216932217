import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ShipmentSizeObject {
  id: number;
  sensivity: number;
  title: Array<any>;
  description: Array<any>;
  width: number;
  height: number;
  weight: number;
  isActive: boolean;
}

export interface ShipmentSizeListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  shipmentSize: Array<ShipmentSizeObject>;
  errors: unknown;
}

@Module
export default class ShipmentSizeModule
  extends VuexModule
  implements ShipmentSizeListInfo
{
  shipmentSize = [] as Array<ShipmentSizeObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get shipmentSize list
   * @returns Array<ShipmentSizeObject>
   */
  get shipmentSizeList(): Array<ShipmentSizeObject> {
    return this.shipmentSize;
  }

  /**
   * Get total items
   * @returns number
   */
  get shipmentSizeListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_SHIPMENT_SIZE](data) {
    this.shipmentSize = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SHIPMENT_SIZE_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_SHIPMENT_SIZE_LIST]() {
    this.shipmentSize = [] as Array<ShipmentSizeObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_SHIPMENT_SIZE_STORE]() {
    this.context.commit(Mutations.RESET_SHIPMENT_SIZE_LIST);
  }

  @Action
  [Actions.CREATE_SHIPMENT_SIZE](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/shipment-size', data)
      .then(() => {
        this.context.commit(Mutations.RESET_SHIPMENT_SIZE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SHIPMENT_SIZE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/shipment-size', id)
      .then(() => {
        this.context.commit(Mutations.RESET_SHIPMENT_SIZE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SHIPMENT_SIZE]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/shipment-size', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_SHIPMENT_SIZE_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_SHIPMENT_SIZE_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_SHIPMENT_SIZE_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_SHIPMENT_SIZE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/shipment-size', id);
  }

  @Action
  [Actions.GET_SHIPMENT_SIZES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/shipment-size')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SHIPMENT_SIZE, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
