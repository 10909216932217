import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface ProductObject {
  id: number;
  offices: string;
  clientId: string;
  isActive: boolean;
  price: number;
  name: Array<any>;
  description: Array<any>;
  iqdPrice: number;
  image: string;
  usdPrice: number;
}

export interface CurrencyObject {
  code: string;
  exchangeRate: number;
}

export interface ProductsListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  products: Array<ProductObject>;
  errors: unknown;
}

@Module
export default class ProductsModule
  extends VuexModule
  implements ProductsListInfo
{
  products = [] as Array<ProductObject>;
  currency = [] as Array<CurrencyObject>;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get products list
   * @returns Array<ProductObject>
   */
  get productList(): Array<ProductObject> {
    return this.products;
  }

  get currencyList(): Array<CurrencyObject> {
    return this.currency;
  }

  /**
   * Get total items
   * @returns number
   */
  get productListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_CURRENCY](data) {
    this.currency = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PRODUCTS](data) {
    this.products = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_PRODUCTS_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_PRODUCTS_LIST]() {
    this.products = [] as Array<ProductObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_PRODUCTS_STORE]() {
    this.context.commit(Mutations.RESET_PRODUCTS_LIST);
  }

  @Action
  [Actions.CREATE_PRODUCT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/products', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_PRODUCTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_PRODUCT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/products', id)
      .then(() => {
        this.context.commit(Mutations.RESET_PRODUCTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_PRODUCT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/products', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_PRODUCTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_PRODUCT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_PRODUCTS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPLOAD_PRODUCT_IMAGE]({ data, productId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/products/uploadProductImage/${productId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_PRODUCT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/products', id);
  }

  @Action
  [Actions.GET_PRODUCTS](input) {
    const params = {
      page: typeof input === 'string' ? 1 : this.page,
      name: input,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/products', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PRODUCTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_CURRENCY]() {
    ApiService.setAuthorizationHeader();
    return ApiService.query('/currency', { params: { page: this.page } })
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }
}
