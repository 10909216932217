import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface DriverObject {
  id: number;
  mobile: number;
  fullName: string;
  email: string;
  typeId: string;
  vehicleId: string;
  officeIds;
  areaIds;
  getProfileImage: string;
  getLicenseImage: string;
  hubId: string;
}

export interface driverListInfo {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  driver: Array<DriverObject>;
  errors: unknown;
}

export interface DriversFilterInfo {
  driverName: string;
}

@Module
export default class DriversModule
  extends VuexModule
  implements driverListInfo
{
  driver = [] as Array<DriverObject>;
  vehicle = [];
  driverTypes = [];
  allAreas = [];
  drivers = [];
  driversFilter = {} as DriversFilterInfo;
  page = 1;
  take = 10;
  itemCount = 0;
  pageCount = 1;
  hasPreviousPage = false;
  hasNextPage = true;
  errors = {};

  /**
   * Get driver list
   * @returns Array<DriverObject>
   */
  get driversList(): Array<DriverObject> {
    return this.driver;
  }

  get driverTypesList(): Array<DriverObject> {
    return this.driverTypes;
  }

  get vehicleList(): Array<DriverObject> {
    return this.vehicle;
  }

  get allAreasList(): Array<DriverObject> {
    return this.allAreas;
  }

  get allDriversList(): Array<DriverObject> {
    return this.drivers;
  }

  /**
   * Get total items
   * @returns number
   */
  get driversListCount(): number {
    return this.itemCount;
  }

  @Mutation
  [Mutations.SET_DRIVERS](data) {
    this.driver = data.data;
    this.page = data.meta.page;
    this.take = data.meta.take;
    this.itemCount = data.meta.itemCount;
    this.pageCount = data.meta.pageCount;
    this.hasPreviousPage = data.meta.hasPreviousPage;
    this.hasNextPage = data.meta.hasNextPage;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_ALL_VEHICLES](data) {
    this.vehicle = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_DRIVER_TYPES](data) {
    this.driverTypes = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_AREAS](data) {
    this.allAreas = data.data;
  }

  @Mutation
  [Mutations.SET_ALL_DRIVERS](data) {
    this.drivers = data.data;
  }

  @Mutation
  [Mutations.SET_DRIVERS_FILTER](obj) {
    this.driversFilter.driverName = obj.driverName;
  }

  @Mutation
  [Mutations.SET_DRIVER_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.RESET_DRIVERS_LIST]() {
    this.driver = [] as Array<DriverObject>;
    this.page = 1;
    this.take = 10;
    this.itemCount = 0;
    this.pageCount = 1;
    this.hasPreviousPage = false;
    this.hasNextPage = true;
    this.errors = {};
  }

  @Action
  [Actions.RESET_DRIVERS_STORE]() {
    this.context.commit(Mutations.RESET_DRIVERS_LIST);
  }

  @Action
  [Actions.UPDATE_DRIVERS_FILTER](val) {
    this.context.commit(Mutations.SET_DRIVERS_FILTER, val);
  }

  @Action
  [Actions.CREATE_DRIVER](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/driver', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_DRIVERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_DRIVER](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('/driver', id)
      .then(() => {
        this.context.commit(Mutations.RESET_DRIVERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_DRIVER]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/driver', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_DRIVERS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_DRIVER_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_DRIVER_CURRENT_PAGE, val);
  }

  @Action
  async [Actions.GET_DRIVER](id) {
    ApiService.setAuthorizationHeader();
    try {
      const { data } = await ApiService.get('/driver', id);
      const hub = data.data.hub;
      await this.context.dispatch(Actions.GET_ALL_HUBS, hub);

      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  @Action
  [Actions.GET_DRIVER_PAYMENTS](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/wallet', id);
  }

  @Action
  [Actions.SEND_PAYMENT_REQUEST](driverId) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/wallet', driverId)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_DRIVERS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_DRIVERS]() {
    const params = {
      page: this.page,
      driverName: this.driversFilter.driverName,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/driver', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DRIVERS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_VEHICLES]() {
    const params = {
      isActive: true,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query('/vehicle-types/all', { params })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_VEHICLES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_DRIVER_TYPES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/driver-types/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ALL_DRIVER_TYPES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_AREAS](obj) {
    ApiService.setAuthorizationHeader();
    const params = {
      driverId: obj.driverId ? obj.driverId : null,
    };
    return ApiService.query('/areas/driver/all', { params })
      .then(({ data }) => {
        data.data = data.data.map((i) => {
          return {
            titleTranslate: i.title.find((r) => r.languageCode === 'en')?.title,
            id: i.id,
          };
        });
        this.context.commit(Mutations.SET_ALL_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.UPLOAD_DRIVER_IMAGE]({ data, driverId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/driver/uploadProfileImage/${driverId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPLOAD_LICENSE_IMAGE]({ data, driverId }) {
    ApiService.setAuthorizationHeader();
    ApiService.setMultipartHeader();
    return ApiService.post(`/driver/uploadLicenseImage/${driverId}`, data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }
  @Action
  [Actions.GET_ALL_DRIVERS]() {
    const params = {
      includeDeleted: true,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/driver/all`, { params });
  }
}
