import ApiService from '@/core/services/ApiService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export interface HubShipment {
  guideVideo: string;
  eventDateTime: string;
  venueEn: string;
  venueAr: string;
  descriptionEn: string;
  descriptionAr: string;
  sizes: string;
  additionalInfoEn: string;
  additionalInfoAr: string;
  organiserName: string;
  venue: string;
  id: string;
  organiserId: number;
  propertiesListedNum: number;
  propertiesSoldNum: number;
  propertiesNotSoldNum: number;
  totalSale: number;
  shipmentPropertysCount: number;
  organisersLocationsId: number | null;
  latitude: number | null;
  longitude: number | null;
  trackingnumbersegments: string;
  isActive: boolean;
  shipmentType: string;
  isExport: boolean;
}

export interface Packages {
  id: string;
  address: string;
  recipientPhoneNumber: string;
  docId: string;
  holderName: string;
  qrCodeImage: string;
}

export interface ShipmentsFilterListInfo {
  officeId: string;
  status: string;
  trackNumber: string;
  packageTrackNumber: string;
  phoneNumber: string;
  docId: string;
  name: string;
  driverName: string;
  packageStatusId: string;
  areaId: string[];
  governorateId: string;
  startDate: string;
  endDate: string;
  hubId: any;
}

export interface ShipmentStatus {
  id: number;
  title: string;
  isActive: boolean;
}
export interface ShipmentOrganasie {
  id: number;
  titleEn: string;
  titleAr: string;
  phone: string;
  is_active: boolean;
  logo: string;
}

export interface ShipmentsListInfo {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  type: string;
  isNext: boolean;
  previous: number;
  hubShipments: Array<HubShipment>;
  errors: unknown;
}

@Module
export default class HubShipmentsModule
  extends VuexModule
  implements ShipmentsListInfo
{
  hubShipments = [] as Array<HubShipment>;
  shipmentProperties = [] as Array<Packages>;
  shipmentStatuses = [] as Array<any>;
  shipmentOrganises = [] as Array<ShipmentOrganasie>;
  shipmentFilter = {} as ShipmentsFilterListInfo;
  gwOfficesListData = [] as Array<any>;
  hubAreasData = [] as Array<any>;
  totalItems = 0;
  currentPage = 1;
  page = 1;
  type = '';
  pageSize = 10;
  previous = 0;
  isNext = true;
  errors = {};

  /**
   * Get hubShipments list
   * @returns Array<HubShipment>
   */
  get hubShipmentsList(): Array<HubShipment> {
    return this.hubShipments;
  }

  /**
   * Get total items
   * @returns number
   */
  get hubShipmentsListCount(): number {
    return this.totalItems;
  }

  /**
   * Get shipment properties list
   * @returns array
   */
  get hubShipmentPackagesList(): Array<Packages> {
    return this.shipmentProperties;
  }

  get hubShipmentPackagesListCount(): number {
    return this.totalItems;
  }

  /**
   * Get gwOfficesList
   * @returns Array<any>
   */
  get gwHunOfficesList(): Array<any> {
    return this.gwOfficesListData;
  }
  /**
   * Get hubAreasList
   * @returns Array<any>
   */
  get hubAreasList(): Array<any> {
    return this.hubAreasData;
  }

  /**
   * Get shipment statuses list
   * @returns array
   */
  get hubShipmentStatusesList(): Array<ShipmentStatus> {
    return this.shipmentStatuses;
  }

  get hubShipmentOrganisesList(): Array<ShipmentOrganasie> {
    return this.shipmentOrganises;
  }

  @Mutation
  [Mutations.SET_HUB_SHIPMENTS](data) {
    this.hubShipments = data.data;
    this.totalItems = data.meta.itemCount;
    this.currentPage = data.meta.page;
    this.pageSize = data.meta.take;
    this.isNext = data.meta.hasNextPage;
    this.previous = data.meta.page;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HUB_SHIPMENT_PROPERTIES](data) {
    this.shipmentProperties = data.data;
    this.totalItems = data.meta.itemCount;
    this.page = data.meta.page;
    this.pageSize = data.meta.take;
    this.isNext = data.meta.hasNextPage;
    this.previous = data.meta.page;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_SHIPMENT_GW_OFFICES](data) {
    this.gwOfficesListData = data.data;
  }

  @Mutation
  [Mutations.SET_HUB_AREAS](data) {
    this.hubAreasData = data.data;
  }

  @Mutation
  [Mutations.SET_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE](page) {
    this.page = page;
  }

  @Mutation
  [Mutations.SET_SHIPMENT_STATUSES](data) {
    this.shipmentStatuses = data.data;
  }

  @Mutation
  [Mutations.SET_SHIPMENT_ORGANISE](data) {
    this.shipmentOrganises = data.data;
  }

  @Mutation
  [Mutations.SET_HUB_SHIPMENTS_CURRENT_PAGE](page) {
    this.currentPage = page;
  }

  @Mutation
  [Mutations.RESET_HUB_SHIPMENTS_LIST]() {
    this.hubShipments = [] as Array<HubShipment>;
    this.type = '';
    this.totalItems = 0;
    this.currentPage = 1;
    this.pageSize = 10;
    this.previous = 0;
    this.isNext = true;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_HUB_SHIPMENTS_FILTER](obj) {
    this.shipmentFilter.officeId = String(obj.officeId);
    this.shipmentFilter.status = String(obj.status);
    this.shipmentFilter.trackNumber = String(obj.trackNumber);
    this.shipmentFilter.areaId = obj.areaId;
    this.shipmentFilter.docId = obj.docId;
    this.shipmentFilter.driverName = obj.driverName;
    this.shipmentFilter.governorateId = obj.governorateId;
    this.shipmentFilter.startDate = obj.startDate;
    this.shipmentFilter.endDate = obj.endDate;
    this.shipmentFilter.hubId = obj.hubId;
    this.shipmentFilter.name = obj.name;
    this.shipmentFilter.packageTrackNumber = obj.packageTrackNumber;
    this.shipmentFilter.phoneNumber = obj.phoneNumber;
    this.shipmentFilter.packageStatusId = obj.packageStatusId;
  }

  @Action
  [Actions.RESET_HUB_SHIPMENTS_STORE]() {
    this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
  }

  @Action
  [Actions.UPDATE_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_HUB_SHIPMENTS_PROPERTY_CURRENT_PAGE, val);
  }

  @Action
  [Actions.UPDATE_HUB_SHIPMENTS_FILTER](val) {
    this.context.commit(Mutations.SET_HUB_SHIPMENTS_FILTER, val);
  }

  @Action
  [Actions.DELETE_HUB_SHIPMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.delete('hub-shipments', id)
      .then(() => {
        this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.DELETE_SHIPMENT_HUB_PROPERTY](id) {
    ApiService.setAuthorizationHeader();
    return (
      ApiService.delete('hub-shipments/packages', id)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            [response.statusText]: [response.data.statusText],
          });
        })
    );
  }

  @Action
  [Actions.CHANGE_HUB_STATUS](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.put('/hub-shipments/status/packages', data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CREATE_HUB_SHIPMENT](data) {
    ApiService.setAuthorizationHeader();
    return ApiService.post('/hub-shipments', data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.CREATE_HUB_SHIPMENT_PACKAGE]({ data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.post(`/hub-shipments/packages`, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HUB_SHIPMENT]({ id, data }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/hub-shipments', id, data)
      .then(() => {
        this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.UPDATE_HUB_SHIPMENT_CURRENT_PAGE](val) {
    this.context.commit(Mutations.SET_HUB_SHIPMENTS_CURRENT_PAGE, val);
  }

  @Action
  [Actions.GET_HUB_SHIPMENT](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('hub-shipments', id);
  }

  @Action
  [Actions.GET_ALL_HUB_SHIPMENT_STATUSES]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/statuses/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HUB_SHIPMENT_STATUSES, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HUB_SHIPMENT_ORGANISERS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/hub-shipments-organisers/all')
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HUB_SHIPMENT_ORGANISE, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HUB_SHIPMENTS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/hub-shipments/all')
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_HUB_SHIPMENT_PROPERTIES](shipmentId) {
    const params = {
      page: this.page,
      take: 50,
    };
    ApiService.setAuthorizationHeader();
    return ApiService.query(`/packages/getHubByShipment/${shipmentId}`, {
      params,
    })
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HUB_SHIPMENT_PROPERTIES, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_ALL_HUB_SHIPMENT_PROPERTIES](shipmentId) {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/packages/getHubAllByShipment/${shipmentId}`)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.GET_HUB_SHIPMENTS](input) {
    ApiService.setAuthorizationHeader();
    const params: Record<string, any> = {
      page: this.currentPage,
      take: 100,
      officeId: this.shipmentFilter.officeId,
      trackNumber: this.shipmentFilter.trackNumber,
      packageTrackNumber: this.shipmentFilter.packageTrackNumber,
      phoneNumber: this.shipmentFilter.phoneNumber,
      docId: this.shipmentFilter.docId,
      name: this.shipmentFilter.name,
      driverName: this.shipmentFilter.driverName,
      packageStatusId: this.shipmentFilter.packageStatusId,
      areaId: this.shipmentFilter.areaId,
      governorateId: this.shipmentFilter.governorateId,
      startDate: this.shipmentFilter.startDate,
      endDate: this.shipmentFilter.endDate,
      hubId: this.shipmentFilter.hubId,
    };
    if (
      this.shipmentFilter.status !== undefined &&
      this.shipmentFilter.status !== ''
    ) {
      params.status = this.shipmentFilter.status;
    }
    return ApiService.query('/hub-shipments', { params })
      .then(({ data }) => {
        data.data = data.data.map((r) => {
          const enDate = new Date(r.createdAt).toLocaleString('en-GB');
          r.eventDate = enDate;
          return r;
        });
        this.context.commit(Mutations.SET_HUB_SHIPMENTS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.EXPORT_HUB_SHIPMENTS](type) {
    const params: Record<string, any> = {
      page: this.currentPage,
      officeId: this.shipmentFilter.officeId,
      trackNumber: this.shipmentFilter.trackNumber,
      packageTrackNumber: this.shipmentFilter.packageTrackNumber,
      phoneNumber: this.shipmentFilter.phoneNumber,
      docId: this.shipmentFilter.docId,
      name: this.shipmentFilter.name,
      driverName: this.shipmentFilter.driverName,
      packageStatusId: this.shipmentFilter.packageStatusId,
      areaId: this.shipmentFilter.areaId,
      governorateId: this.shipmentFilter.governorateId,
      startDate: this.shipmentFilter.startDate,
      endDate: this.shipmentFilter.endDate,
      hubId: this.shipmentFilter.hubId,
    };
    if (
      this.shipmentFilter.status !== undefined &&
      this.shipmentFilter.status !== ''
    ) {
      params.status = this.shipmentFilter.status;
    }
    ApiService.setAuthorizationHeader();
    return ApiService.query('/hub-shipments/export', { params })
      .then(({ data }) => {
        data.data = data?.data.map((el) => ({
          ...el,
          driverName: el.Captain?.fullName,
        }));
        this.context.commit(Mutations.SET_EXPORT_SHIPMENTS, data);
        return data.data;
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.message],
        // });
      });
  }

  @Action
  [Actions.GET_HUB_AREAS]() {
    ApiService.setAuthorizationHeader();
    return ApiService.get(`/areas/all`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_HUB_AREAS, data);
      })
      .catch(({ response }) => {
        console.error(response);
        // this.context.commit(Mutations.SET_ERROR, {
        //   [response.statusText]: [response.data.statusText],
        // });
      });
  }

  @Action
  [Actions.UPDATE_HUB_SHIPMENTS_PACKAGE]({ data, shipmentId }) {
    ApiService.setAuthorizationHeader();
    return ApiService.update('/auctions/properties', shipmentId, data)
      .then(({ data }) => {
        this.context.commit(Mutations.RESET_HUB_SHIPMENTS_LIST);
        return data.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, {
          [response.statusText]: [response.data.statusText],
        });
      });
  }

  @Action
  [Actions.GET_HUB_SHIPMENT_PACKAGE](id) {
    ApiService.setAuthorizationHeader();
    return ApiService.get('/packages', id);
  }
}
